const boardsData = {
  "AI Board of Artificial Intelligence": {
    heading: "AI Board of Artificial Intelligence",
    mainHeading:
      "Master the Future with the AI Board of Artificial Intelligence",
    summary:
      "The AI Board of Artificial Intelligence is the ultimate program for anyone who wants to learn about AI and how it works in the real world. This program is open to everyone, no matter their age, background, or education level. If you're curious about machine learning, robotics, or how AI is shaping our future, this is the perfect starting point. Your journey begins with a Training Needs Analysis (TNA) to understand your current knowledge. Based on this, the program is customized to match your skills and goals. You’ll learn about AI concepts like neural networks, algorithms, and automation. You’ll also discover how AI is used in industries like healthcare, gaming, and business. This program requires you to complete your studies and take the exam within six months. Exams focus on real-world AI problems, like creating AI models or improving existing technologies. Once you pass with a top score, you’ll receive a blockchain-secured certification, trusted globally. Whether you want to be an AI engineer, researcher, or entrepreneur, the AI Board of Artificial Intelligence prepares you for success. Exams can be taken online or at test centers worldwide, making it flexible and accessible.",
    highlights: [
      "Open to everyone, regardless of education or age.",
      "Personalized programs based on your current knowledge and goals.",
      "Learn AI concepts like machine learning, neural networks, and automation.",
      "Explore how AI transforms industries such as healthcare and business.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world AI challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in AI engineering, research, or entrepreneurship.",
      "Aligned with the latest global AI standards.",
    ],
    benefits: [
      "Open access to anyone, regardless of background or age.",
      "Personalized learning paths based on your skills and experience.",
      "Gain practical knowledge of AI concepts and tools.",
      "Certifications are globally trusted and secured on the blockchain.",
      "Develop real-world problem-solving skills in AI.",
      "Structured timeline ensures discipline and focus.",
      "Opportunities to explore AI applications in multiple industries.",
      "Suitable for aspiring engineers, researchers, or innovators.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn how to apply AI in healthcare, gaming, and business.",
    ],
    features: [
      "Comprehensive study of AI topics like neural networks and machine learning.",
      "Training Needs Analysis to tailor the learning experience.",
      "Advanced modules on real-world AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed around practical AI challenges and case studies.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Continuous updates to reflect the latest AI technologies and trends.",
      "Aligns with international benchmarks for AI education and training.",
    ],
  },
  "AI Board of Data Science": {
    heading: "AI Board of Data Science",
    mainHeading: "Unlock the Power of Data with the AI Board of Data Science",
    summary:
      "The AI Board of Data Science is the perfect program for anyone who wants to explore how data shapes our world. Open to everyone, no matter your age or background, this program helps you learn how to collect, analyze, and interpret data using cutting-edge tools and techniques powered by Artificial Intelligence (AI). You’ll start with a Training Needs Analysis (TNA) to evaluate your current skills, and then receive a personalized program tailored to your needs. Learn essential concepts like data visualization, machine learning, and predictive modeling. This program requires you to complete your studies and take the exam within six months. Exams focus on solving real-world problems, like creating data models or analyzing trends in industries like healthcare, finance, and technology. Certifications are secured via blockchain, ensuring global trust and recognition. Whether you want to become a data scientist, analyst, or AI specialist, the AI Board of Data Science prepares you for success. Flexible exam options, including online or global test centers, make this program accessible to all.",
    highlights: [
      "Open to everyone, regardless of age or background.",
      "Personalized programs based on your current skills and goals.",
      "Learn core concepts like data visualization, machine learning, and predictive modeling.",
      "Understand how AI enhances data analysis across industries.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world data challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in data science, analysis, or AI development.",
      "Aligned with the latest global data science standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to your skills and goals.",
      "Learn practical tools and techniques for data analysis.",
      "Certifications are globally trusted and secured on the blockchain.",
      "Develop real-world problem-solving skills in data science.",
      "Structured program ensures focus and discipline with a six-month timeline.",
      "Opportunities to explore AI applications in data analysis and prediction.",
      "Suitable for aspiring data scientists, analysts, and AI developers.",
      "Accessible exams online or at physical centers worldwide.",
      "Gain skills applicable across industries like healthcare, finance, and technology.",
    ],
    features: [
      "Comprehensive study of data science concepts like machine learning and predictive modeling.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on real-world data challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and analytical skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect the latest data science technologies and trends.",
      "Aligned with international benchmarks for data science education and training.",
    ],
  },
  "AI Board of Machine Learning": {
    heading: "AI Board of Machine Learning",
    mainHeading:
      "Master Machine Learning with the AI Board of Machine Learning",
    summary:
      "The AI Board of Machine Learning is designed for anyone curious about how machines learn and make decisions. This program is open to everyone, regardless of age, education, or background. Whether you’re a beginner or have some knowledge, this program will guide you through key concepts like neural networks, algorithms, and data-driven decision-making. The journey begins with a Training Needs Analysis (TNA) to understand your current skills and knowledge. The program is then personalized to suit your needs, helping you learn topics like supervised and unsupervised learning, deep learning, and reinforcement learning. Exams are time-bound and focus on solving real-world challenges, such as building machine learning models or optimizing algorithms. Upon passing, you’ll receive a blockchain-secured certification that is globally recognized and trusted. Whether you aim to become a machine learning engineer, AI researcher, or data scientist, this program equips you with the skills needed for success. Flexible options allow you to take exams online or at test centers worldwide, making it convenient and accessible for all.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs based on your skills and goals.",
      "Learn key concepts like supervised, unsupervised, and reinforcement learning.",
      "Explore how machine learning powers industries such as healthcare, finance, and gaming.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world machine learning problems.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in machine learning engineering, AI research, or data science.",
      "Aligned with the latest global machine learning standards.",
    ],
    benefits: [
      "Open to all, regardless of prior knowledge or background.",
      "Personalized learning paths tailored to your skills.",
      "Gain practical knowledge of machine learning concepts and tools.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in machine learning.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore machine learning applications in various industries.",
      "Suitable for aspiring engineers, data scientists, and AI researchers.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to create and optimize machine learning models for real-world applications.",
    ],
    features: [
      "Comprehensive coverage of machine learning topics like deep learning and reinforcement learning.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on real-world machine learning challenges.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and technical skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect the latest advancements in machine learning.",
      "Aligned with international benchmarks for machine learning education and training.",
    ],
  },
  "AI Board of Robotics": {
    heading: "AI Board of Robotics",
    mainHeading: "Master Robotics with the AI Board of Robotics",
    summary:
      "The AI Board of Robotics is the perfect program for anyone fascinated by robots and how they work. Open to everyone, regardless of age, background, or education level, this program teaches you how to design, build, and program robots using cutting-edge Artificial Intelligence (AI). Your journey begins with a Training Needs Analysis (TNA) to assess your skills and knowledge. The program is then personalized to help you learn concepts like robot mechanics, AI integration, and automation. You’ll also explore how robots are used in industries such as manufacturing, healthcare, and space exploration. The program is time-bound, requiring you to complete your studies and take the exam within six months. Exams are practical, focusing on real-world robotics challenges like building and programming functional robots. Upon passing, you’ll earn a blockchain-secured certification, recognized globally. Whether you want to become a robotics engineer, AI developer, or automation specialist, this program prepares you for a rewarding career. Flexible exam options, including online and global centers, make this program accessible to all.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs based on your current knowledge and goals.",
      "Learn robotics concepts like mechanics, AI integration, and automation.",
      "Explore how robots are transforming industries like healthcare and manufacturing.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world robotics challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in robotics engineering, AI development, or automation.",
      "Aligned with the latest global robotics standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to your skills and interests.",
      "Gain practical knowledge of robotics and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in robotics.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore robotics applications in various industries.",
      "Suitable for aspiring robotics engineers, developers, and automation specialists.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to design, build, and program robots for real-world applications.",
    ],
    features: [
      "Comprehensive study of robotics concepts like mechanics and automation.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on real-world robotics challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and technical skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect the latest advancements in robotics.",
      "Aligned with international benchmarks for robotics education and training.",
    ],
  },
  "AI Board of Computer Science": {
    heading: "AI Board of Computer Science",
    mainHeading:
      "Master the Digital World with the AI Board of Computer Science",
    summary:
      "The AI Board of Computer Science is an excellent program for anyone who wants to dive into the world of technology and computing. Open to everyone, regardless of age, background, or education level, this program teaches you the essential concepts of computer science while integrating cutting-edge Artificial Intelligence (AI) tools. The journey begins with a Training Needs Analysis (TNA) to assess your skills and knowledge. The program is then personalized to help you learn topics like programming, algorithms, data structures, and computer networks. You’ll also explore the role of AI in advancing computer science, including topics like automation, machine learning, and cybersecurity. This program is time-bound, requiring you to complete your studies and take the exam within six months. Exams focus on real-world computer science challenges, like building software, optimizing networks, or enhancing AI applications. Upon passing, you’ll earn a blockchain-secured certification that is globally recognized. Whether you aspire to become a software developer, IT specialist, or AI engineer, this program equips you with the skills needed for success. Flexible exam options, including online and global centers, make this program accessible to all.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs based on your current knowledge and goals.",
      "Learn core computer science concepts like programming and algorithms.",
      "Explore AI applications in computer science, including automation and machine learning.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world computer science challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in software development, IT, or AI engineering.",
      "Aligned with the latest global computer science standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to your skills and interests.",
      "Gain practical knowledge of computer science and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in computer science.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in computing and automation.",
      "Suitable for aspiring software developers, IT specialists, and engineers.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to build software, optimize systems, and enhance AI technologies.",
    ],
    features: [
      "Comprehensive study of computer science topics like programming and data structures.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on real-world computer science challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and technical skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect the latest advancements in computer science.",
      "Aligned with international benchmarks for computer science education and training.",
    ],
  },
  "AI Board of Software Engineering": {
    heading: "AI Board of Software Engineering",
    mainHeading: "Design the Future with the AI Board of Software Engineering",
    summary:
      "The AI Board of Software Engineering is designed for anyone interested in learning how to build software applications and systems. This program is open to everyone, regardless of age, education, or background. It equips you with essential knowledge in software development, programming languages, and advanced AI-driven tools. The journey begins with a Training Needs Analysis (TNA) to assess your current skills and knowledge. Based on this, a personalized program is created, covering topics like software architecture, coding best practices, testing, and deployment. You’ll also explore how Artificial Intelligence (AI) enhances software development processes, including automation and optimization. The program is time-bound, requiring you to complete your studies and take the exam within six months. Exams focus on real-world software engineering challenges like creating scalable applications and debugging complex systems. Upon passing, you’ll earn a blockchain-secured certification, recognized globally. Whether you aim to become a software engineer, AI developer, or tech entrepreneur, this program prepares you for success. Flexible options allow you to take exams online or at test centers worldwide, making it accessible to all.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs based on your current skills and goals.",
      "Learn core software engineering concepts like programming and testing.",
      "Explore how AI enhances software development and automation.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world software engineering challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in software engineering, AI development, or entrepreneurship.",
      "Aligned with the latest global software engineering standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to your skills and interests.",
      "Gain practical knowledge of software engineering and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in software engineering.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in software development and automation.",
      "Suitable for aspiring software engineers, developers, and tech entrepreneurs.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to design, develop, and deploy software applications.",
    ],
    features: [
      "Comprehensive study of software engineering topics like architecture and testing.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on real-world software engineering challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and technical skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect the latest advancements in software engineering.",
      "Aligned with international benchmarks for software engineering education and training.",
    ],
  },
  "AI Board of Civil Engineering": {
    heading: "AI Board of Civil Engineering",
    mainHeading: "Build the Future with the AI Board of Civil Engineering",
    summary:
      "The AI Board of Civil Engineering is a comprehensive program for anyone passionate about designing and building infrastructure. Open to everyone, regardless of age, background, or education, this program provides the tools to master civil engineering with the help of Artificial Intelligence (AI). Your journey begins with a Training Needs Analysis (TNA) to assess your knowledge and skills. Based on this, a personalized program is created, covering topics such as structural design, environmental engineering, project management, and the integration of AI in construction. This program is time-bound, requiring you to complete your studies and take the exam within six months. Exams focus on solving real-world challenges, such as designing sustainable buildings, managing large-scale projects, and applying AI to enhance construction processes. Upon passing, you’ll earn a blockchain-secured certification that is globally recognized. Whether you aim to become a civil engineer, project manager, or infrastructure specialist, this program equips you with the skills to succeed. Flexible exam options, including online and global centers, make the program accessible to all.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current knowledge and goals.",
      "Learn key concepts like structural design, environmental engineering, and project management.",
      "Explore the role of AI in civil engineering and construction.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world civil engineering challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in civil engineering, project management, or infrastructure development.",
      "Aligned with the latest global civil engineering standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to your skills and goals.",
      "Gain practical knowledge of civil engineering and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in civil engineering.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in construction and infrastructure.",
      "Suitable for aspiring civil engineers, project managers, and environmental specialists.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to design, manage, and construct infrastructure for the modern world.",
    ],
    features: [
      "Comprehensive study of civil engineering topics like structural design and environmental impact.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on real-world civil engineering challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and technical skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect the latest advancements in civil engineering and construction.",
      "Aligned with international benchmarks for civil engineering education and training.",
    ],
  },
  "AI Board of Mechanical Engineering": {
    heading: "AI Board of Mechanical Engineering",
    mainHeading:
      "Revolutionize Engineering with the AI Board of Mechanical Engineering",
    summary:
      "The AI Board of Mechanical Engineering is the perfect program for anyone passionate about designing machines and systems. Open to everyone, regardless of age, background, or education, this program combines traditional mechanical engineering concepts with cutting-edge Artificial Intelligence (AI) tools. Your journey begins with a Training Needs Analysis (TNA) to evaluate your current skills and knowledge. Based on this, a personalized program is created, covering topics like thermodynamics, robotics, and CAD (Computer-Aided Design). You’ll also learn how AI enhances mechanical design, automation, and manufacturing. The program is time-bound, requiring you to complete your studies and take the exam within six months. Exams are practical and focus on solving real-world engineering problems like optimizing machine performance and automating manufacturing processes. Upon passing, you’ll earn a blockchain-secured certification that is globally recognized. Whether you aim to become a mechanical engineer, automation specialist, or robotics expert, this program equips you with the skills to excel. Flexible exam options, including online and global centers, make the program accessible to all.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current skills and goals.",
      "Learn core concepts like thermodynamics, robotics, and CAD.",
      "Explore how AI transforms mechanical engineering and automation.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world mechanical engineering challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in mechanical engineering, robotics, or automation.",
      "Aligned with the latest global mechanical engineering standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to your skills and interests.",
      "Gain practical knowledge of mechanical engineering and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in mechanical engineering.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in manufacturing and design.",
      "Suitable for aspiring mechanical engineers, automation specialists, and robotics experts.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to design, build, and optimize machines and systems.",
    ],
    features: [
      "Comprehensive study of mechanical engineering topics like thermodynamics and CAD.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on real-world mechanical engineering challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and technical skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect the latest advancements in mechanical engineering and automation.",
      "Aligned with international benchmarks for mechanical engineering education and training.",
    ],
  },
  "AI Board of Electrical Engineering": {
    heading: "AI Board of Electrical Engineering",
    mainHeading:
      "Empower the Future with the AI Board of Electrical Engineering",
    summary:
      "The AI Board of Electrical Engineering is perfect for those interested in mastering how electricity powers the world. Open to everyone, regardless of age or background, this program combines traditional electrical engineering concepts with Artificial Intelligence (AI). You’ll start with a Training Needs Analysis (TNA) to assess your current skills. The program is tailored to include topics like circuit design, renewable energy, and AI in smart grids. Exams are practical, focusing on real-world challenges like designing energy-efficient systems. The program is time-bound, with a six-month completion window, and upon passing, you’ll receive a blockchain-secured certification recognized globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current skills.",
      "Learn core topics like circuit design, smart grids, and renewable energy.",
      "Explore AI's role in electrical systems and automation.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world electrical engineering challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in electrical engineering or energy management.",
      "Aligned with the latest global electrical engineering standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to your skills and goals.",
      "Gain practical knowledge of electrical engineering and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in electrical engineering.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in energy and automation.",
      "Suitable for aspiring electrical engineers and energy specialists.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to design, optimize, and innovate in electrical systems.",
    ],
    features: [
      "Comprehensive study of electrical engineering topics like circuits and AI-driven grids.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on renewable energy and energy-efficient systems.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and technical skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect the latest advancements in electrical engineering.",
      "Aligned with international benchmarks for electrical engineering education.",
    ],
  },
  "AI Board of Aerospace Engineering": {
    heading: "AI Board of Aerospace Engineering",
    mainHeading: "Reach New Heights with the AI Board of Aerospace Engineering",
    summary:
      "The AI Board of Aerospace Engineering is designed for those passionate about flight and space exploration. This program combines aerospace fundamentals with Artificial Intelligence (AI) to prepare you for a cutting-edge career. Starting with a Training Needs Analysis (TNA), the program covers aerodynamics, propulsion, satellite systems, and AI in autonomous drones. Exams focus on practical challenges like optimizing flight designs or creating AI-driven space solutions. The program is time-bound, requiring completion within six months. Upon passing, you’ll earn a blockchain-secured certification recognized globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current skills.",
      "Learn aerospace topics like propulsion, satellite systems, and aerodynamics.",
      "Explore AI's role in drones, autonomous aircraft, and space systems.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world aerospace challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in aerospace engineering or space exploration.",
      "Aligned with the latest global aerospace standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to your skills and goals.",
      "Gain practical knowledge of aerospace engineering and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in aerospace engineering.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in aviation and space systems.",
      "Suitable for aspiring aerospace engineers, drone specialists, and space scientists.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to design, optimize, and innovate in aerospace systems.",
    ],
    features: [
      "Comprehensive study of aerospace topics like propulsion and satellite design.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on drones, AI-powered navigation, and space engineering.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and technical skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect the latest advancements in aerospace engineering.",
      "Aligned with international benchmarks for aerospace education and training.",
    ],
  },
  "AI Board of Telecommunications": {
    heading: "AI Board of Telecommunications",
    mainHeading:
      "Transform Connectivity with the AI Board of Telecommunications",
    summary:
      "The AI Board of Telecommunications prepares you to master modern communication technologies. This program combines telecommunications fundamentals with AI to address real-world connectivity challenges. Starting with a Training Needs Analysis (TNA), the program covers wireless networks, fiber optics, 5G, and AI-driven communication systems. Exams focus on designing efficient networks and optimizing communication systems. The program is time-bound, requiring completion within six months, and offers blockchain-secured certifications recognized globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current skills.",
      "Learn key topics like wireless networks, fiber optics, and 5G.",
      "Explore AI's role in optimizing and automating telecommunications.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world telecommunications challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in network engineering or telecom innovation.",
      "Aligned with the latest global telecommunications standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to your skills and goals.",
      "Gain practical knowledge of telecommunications and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in communication systems.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in telecom automation and 5G.",
      "Suitable for aspiring network engineers, telecom specialists, and AI developers.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to design and optimize communication networks for modern needs.",
    ],
    features: [
      "Comprehensive study of telecommunications topics like fiber optics and 5G.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on real-world telecom challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and technical skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect the latest advancements in telecommunications.",
      "Aligned with international benchmarks for telecommunications education.",
    ],
  },
  "AI Board of Quantum Computing": {
    heading: "AI Board of Quantum Computing",
    mainHeading: "Explore the Future with the AI Board of Quantum Computing",
    summary:
      "The AI Board of Quantum Computing is the perfect program for those fascinated by cutting-edge technology and advanced computation. This program combines quantum computing basics with AI applications. Starting with a Training Needs Analysis (TNA), the program covers quantum algorithms, quantum cryptography, and AI-powered quantum simulations. Exams focus on solving challenges like optimizing quantum circuits or developing secure quantum protocols. The program is time-bound, requiring completion within six months, and provides blockchain-secured certifications recognized globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current skills.",
      "Learn key topics like quantum algorithms and quantum cryptography.",
      "Explore AI's role in quantum simulations and computation.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world quantum computing challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in quantum research or advanced AI development.",
      "Aligned with the latest global quantum computing standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to your skills and goals.",
      "Gain practical knowledge of quantum computing and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in quantum computation.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in quantum research and simulation.",
      "Suitable for aspiring quantum scientists, cryptographers, and AI researchers.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to design, optimize, and innovate in quantum computing systems.",
    ],
    features: [
      "Comprehensive study of quantum computing topics like algorithms and cryptography.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on real-world quantum challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and technical skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect the latest advancements in quantum computing.",
      "Aligned with international benchmarks for quantum computing education.",
    ],
  },
  "AI Board of Nanotechnology": {
    heading: "AI Board of Nanotechnology",
    mainHeading:
      "Revolutionize the Micro World with the AI Board of Nanotechnology",
    summary:
      "The AI Board of Nanotechnology is ideal for anyone fascinated by the tiny world of atoms and molecules. This program combines nanotechnology basics with Artificial Intelligence (AI) to empower students to create innovative solutions in fields like medicine, energy, and materials science. You’ll begin with a Training Needs Analysis (TNA) to assess your knowledge, followed by a personalized program covering topics like nanomaterials, molecular engineering, and AI-powered nanotech simulations. Exams focus on real-world applications such as designing nanodevices or improving energy efficiency. The program is time-bound, requiring completion within six months, with blockchain-secured certifications awarded upon passing.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current skills and goals.",
      "Learn about nanomaterials, molecular engineering, and AI simulations.",
      "Explore AI's role in advancing nanotechnology.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world nanotechnology challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in nanotech research or material science.",
      "Aligned with the latest global nanotechnology standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to your skills and interests.",
      "Gain practical knowledge of nanotechnology and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in nanotechnology.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in medical and material sciences.",
      "Suitable for aspiring nanotech researchers, engineers, and AI developers.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to design and optimize nanodevices and systems.",
    ],
    features: [
      "Comprehensive study of nanotechnology topics like nanomaterials and molecular engineering.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on real-world nanotechnology challenges.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and technical skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect the latest advancements in nanotechnology.",
      "Aligned with international benchmarks for nanotechnology education.",
    ],
  },
  "AI Board of Space Science": {
    heading: "AI Board of Space Science",
    mainHeading: "Explore the Universe with the AI Board of Space Science",
    summary:
      "The AI Board of Space Science is for those curious about the mysteries of the cosmos. This program merges space science with Artificial Intelligence (AI) to help students master planetary exploration, astrophysics, and satellite systems. Beginning with a Training Needs Analysis (TNA), the program covers celestial mechanics, space technology, and AI-driven space simulations. Exams focus on practical challenges like designing space missions or analyzing planetary data. This time-bound program must be completed within six months, with blockchain-secured certifications awarded upon passing.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current knowledge and goals.",
      "Learn celestial mechanics, space technology, and satellite systems.",
      "Explore AI's role in space exploration and mission planning.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world space science challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in space science, astrophysics, or satellite technology.",
      "Aligned with the latest global space science standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to your skills and goals.",
      "Gain practical knowledge of space science and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in space exploration.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in planetary exploration.",
      "Suitable for aspiring space scientists, astrophysicists, and engineers.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to design and manage space missions and technologies.",
    ],
    features: [
      "Comprehensive study of space science topics like celestial mechanics and AI simulations.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on real-world space science challenges.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and technical skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect the latest advancements in space science.",
      "Aligned with international benchmarks for space science education.",
    ],
  },
  "AI Board of Hydrology and Water Resources": {
    heading: "AI Board of Hydrology and Water Resources",
    mainHeading:
      "Master Water Systems with the AI Board of Hydrology and Water Resources",
    summary:
      "The AI Board of Hydrology and Water Resources is for those passionate about managing Earth's most precious resource. This program blends hydrology and Artificial Intelligence (AI) to address real-world challenges in water management, conservation, and sustainability. You’ll begin with a Training Needs Analysis (TNA) and progress through personalized topics like watershed management, flood control, and AI-driven water resource modeling. Exams focus on practical applications like designing sustainable water systems or managing water crises. The program is time-bound and offers blockchain-secured certifications upon passing.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current skills and goals.",
      "Learn topics like watershed management, flood control, and sustainability.",
      "Explore AI's role in managing water systems and conservation.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world hydrology and water resource challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in water resource management or environmental engineering.",
      "Aligned with the latest global hydrology standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to your skills and goals.",
      "Gain practical knowledge of hydrology and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in water resource management.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in water conservation and crisis management.",
      "Suitable for aspiring hydrologists, engineers, and environmental specialists.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to manage and design sustainable water systems.",
    ],
    features: [
      "Comprehensive study of hydrology topics like water systems and flood control.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on real-world water management challenges.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and technical skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect the latest advancements in hydrology.",
      "Aligned with international benchmarks for hydrology education.",
    ],
  },
  "AI Board of Biomedical Engineering": {
    heading: "AI Board of Biomedical Engineering",
    mainHeading:
      "Transform Healthcare with the AI Board of Biomedical Engineering",
    summary:
      "The AI Board of Biomedical Engineering is ideal for those eager to innovate in healthcare. This program combines biomedical engineering and Artificial Intelligence (AI) to prepare students for advancing medical technology. Starting with a Training Needs Analysis (TNA), the program covers topics like medical imaging, AI-powered prosthetics, and bioinformatics. Exams focus on practical challenges like designing medical devices or optimizing healthcare systems. This program is time-bound, requiring completion within six months, with blockchain-secured certifications awarded upon passing.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current skills and goals.",
      "Learn biomedical topics like imaging, prosthetics, and bioinformatics.",
      "Explore AI's role in transforming healthcare and medical devices.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world biomedical engineering challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in biomedical engineering or healthcare innovation.",
      "Aligned with the latest global biomedical engineering standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to your skills and goals.",
      "Gain practical knowledge of biomedical engineering and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in healthcare technology.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in healthcare and medical devices.",
      "Suitable for aspiring biomedical engineers and healthcare innovators.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to design and optimize medical devices and systems.",
    ],
    features: [
      "Comprehensive study of biomedical topics like imaging and prosthetics.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on real-world biomedical engineering challenges.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and technical skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect the latest advancements in biomedical engineering.",
      "Aligned with international benchmarks for biomedical engineering education.",
    ],
  },
  "AI Board of Systems Engineering": {
    heading: "AI Board of Systems Engineering",
    mainHeading:
      "Optimize Complex Systems with the AI Board of Systems Engineering",
    summary:
      "The AI Board of Systems Engineering is ideal for those passionate about designing and managing large, complex systems. Open to everyone, regardless of age or educational background, this program integrates systems engineering principles with cutting-edge Artificial Intelligence (AI) tools. Starting with a Training Needs Analysis (TNA), the program covers topics like system design, optimization, and AI-enhanced operations. Exams focus on solving real-world challenges like improving workflows or optimizing supply chains. The program is time-bound and must be completed within six months. Blockchain-secured certifications, globally trusted, are awarded upon passing.",
    highlights: [
      "Open to everyone, regardless of age or background.",
      "Personalized programs tailored to your current knowledge and goals.",
      "Learn core topics like system design, integration, and optimization.",
      "Explore AI's role in enhancing system performance.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world systems engineering challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in systems engineering or process management.",
      "Aligned with the latest global systems engineering standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to your skills and goals.",
      "Gain practical knowledge of systems engineering and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in systems management.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in supply chains and workflows.",
      "Suitable for aspiring systems engineers and operations specialists.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to design, optimize, and manage complex systems effectively.",
    ],
    features: [
      "Comprehensive study of systems engineering topics like design and integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on real-world systems challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and technical skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect the latest advancements in systems engineering.",
      "Aligned with international benchmarks for systems engineering education.",
    ],
  },
  "AI Board of AI Ethics": {
    heading: "AI Board of AI Ethics",
    mainHeading: "Shape the Future Responsibly with the AI Board of AI Ethics",
    summary:
      "The AI Board of AI Ethics is perfect for those who want to explore the ethical implications of Artificial Intelligence (AI). This program focuses on responsible AI development and governance. Open to everyone, it begins with a Training Needs Analysis (TNA) to understand your current knowledge. The personalized curriculum covers topics like bias in AI, data privacy, and ethical decision-making. Exams focus on real-world challenges like ensuring fairness in AI algorithms. This time-bound program must be completed within six months, and passing candidates earn blockchain-secured certifications recognized globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current knowledge.",
      "Learn about bias, privacy, and governance in AI systems.",
      "Explore how ethical principles apply to AI development.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world ethical challenges in AI.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in AI governance, policy-making, or research.",
      "Aligned with the latest global standards for ethical AI development.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to your skills and goals.",
      "Gain practical knowledge of AI ethics and responsible development.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in ethical AI applications.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in ethical frameworks and policies.",
      "Suitable for aspiring AI ethicists, policymakers, and researchers.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to design and implement responsible AI solutions.",
    ],
    features: [
      "Comprehensive study of AI ethics topics like bias and governance.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on real-world ethical challenges in AI.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and critical thinking skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect the latest advancements in AI ethics.",
      "Aligned with international benchmarks for ethical AI education.",
    ],
  },
  "AI Board of Quantum Physics": {
    heading: "AI Board of Quantum Physics",
    mainHeading:
      "Unlock the Mysteries of the Universe with the AI Board of Quantum Physics",
    summary:
      "The AI Board of Quantum Physics is tailored for those fascinated by the strange and exciting world of quantum mechanics. This program combines fundamental quantum concepts with AI-powered tools for simulations and problem-solving. Starting with a Training Needs Analysis (TNA), the curriculum includes topics like wave-particle duality, quantum algorithms, and quantum computing. Exams focus on solving challenges like designing quantum experiments or optimizing quantum circuits. This time-bound program is completed within six months, with blockchain-secured certifications awarded globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current knowledge.",
      "Learn quantum mechanics, algorithms, and quantum computing.",
      "Explore AI's role in quantum simulations and problem-solving.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world quantum physics challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in quantum research or advanced AI development.",
      "Aligned with the latest global quantum physics standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to your skills and goals.",
      "Gain practical knowledge of quantum physics and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in quantum physics.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in quantum experiments.",
      "Suitable for aspiring quantum scientists and AI researchers.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to design and optimize quantum systems.",
    ],
    features: [
      "Comprehensive study of quantum physics topics like algorithms and duality.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on real-world quantum challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and critical thinking.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect the latest advancements in quantum physics.",
      "Aligned with international benchmarks for quantum physics education.",
    ],
  },
  "AI Board of Bioinformatics": {
    heading: "AI Board of Bioinformatics",
    mainHeading: "Decode Life with the AI Board of Bioinformatics",
    summary:
      "The AI Board of Bioinformatics is designed for those interested in exploring the intersection of biology, data science, and Artificial Intelligence (AI). This program starts with a Training Needs Analysis (TNA) to assess your knowledge, followed by a personalized curriculum covering genomics, protein analysis, and AI-driven bioinformatics tools. Exams focus on solving challenges like identifying genetic markers or building predictive models for healthcare. The program is time-bound and must be completed within six months, with blockchain-secured certifications awarded globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current knowledge.",
      "Learn genomics, protein analysis, and AI-powered bioinformatics tools.",
      "Explore AI's role in advancing biological research and healthcare.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world bioinformatics challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in bioinformatics, genomics, or healthcare technology.",
      "Aligned with the latest global bioinformatics standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to your skills and goals.",
      "Gain practical knowledge of bioinformatics and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in biological research.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in healthcare and genomics.",
      "Suitable for aspiring bioinformaticians and genomic researchers.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to analyze biological data and create predictive healthcare models.",
    ],
    features: [
      "Comprehensive study of bioinformatics topics like genomics and protein analysis.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on real-world bioinformatics challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and data analysis skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect the latest advancements in bioinformatics.",
      "Aligned with international benchmarks for bioinformatics education.",
    ],
  },
  "AI Medical Board of General Practice": {
    heading: "AI Medical Board of General Practice",
    mainHeading:
      "Transform Primary Care with the AI Medical Board of General Practice",
    summary:
      "The AI Medical Board of General Practice is ideal for anyone who wants to master the basics of medical care. This program combines general practice training with the power of Artificial Intelligence (AI) to improve diagnostics, patient care, and treatment planning. You’ll start with a Training Needs Analysis (TNA) to assess your skills and knowledge. The program includes topics like medical ethics, common illnesses, and AI-powered patient management. Exams focus on solving real-world healthcare challenges. The program is time-bound, with a six-month completion requirement, and offers blockchain-secured certifications recognized globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your skills and goals.",
      "Learn general practice fundamentals like diagnostics and treatment planning.",
      "Explore AI's role in patient care and medical technology.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world medical challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in general medical practice.",
      "Aligned with the latest global healthcare standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and backgrounds.",
      "Personalized learning paths tailored to individual skills.",
      "Gain practical knowledge of general practice and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in patient care.",
      "Structured program ensures discipline with a six-month timeline.",
      "Opportunities to explore AI applications in medical diagnostics.",
      "Suitable for aspiring general practitioners and healthcare specialists.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to provide effective primary care solutions.",
    ],
    features: [
      "Comprehensive study of general practice topics like diagnosis and ethics.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on real-world healthcare challenges and AI tools.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and critical thinking.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in medical practice and AI.",
      "Aligned with international benchmarks for medical education.",
    ],
  },
  "AI Medical Board of Surgery": {
    heading: "AI Medical Board of Surgery",
    mainHeading: "Master Precision with the AI Medical Board of Surgery",
    summary:
      "The AI Medical Board of Surgery is for those passionate about surgical techniques and advancements. This program integrates traditional surgical education with AI-enhanced tools to improve precision and outcomes. Starting with a Training Needs Analysis (TNA), the program covers surgical procedures, patient safety, and AI-powered simulations. Exams focus on practical applications like planning surgeries and optimizing outcomes. This time-bound program must be completed within six months, with blockchain-secured certifications awarded globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your knowledge and goals.",
      "Learn core surgical procedures and patient safety standards.",
      "Explore AI's role in surgical planning and precision tools.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world surgical challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in surgical practice or innovation.",
      "Aligned with the latest global surgical standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to individual skills.",
      "Gain practical knowledge of surgery and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in surgical procedures.",
      "Structured program ensures discipline with a six-month timeline.",
      "Opportunities to explore AI applications in surgical precision and robotics.",
      "Suitable for aspiring surgeons and surgical innovators.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to optimize patient outcomes with AI-driven techniques.",
    ],
    features: [
      "Comprehensive study of surgical topics like techniques and patient safety.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on surgical challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and precision skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in surgery and AI tools.",
      "Aligned with international benchmarks for surgical education.",
    ],
  },
  "AI Board of Pharmacy": {
    heading: "AI Board of Pharmacy",
    mainHeading: "Redefine Healthcare with the AI Board of Pharmacy",
    summary:
      "The AI Board of Pharmacy is for anyone interested in understanding medicines and their role in healthcare. This program combines pharmacy education with AI to improve drug development and patient care. Starting with a Training Needs Analysis (TNA), the curriculum includes pharmacology, drug safety, and AI-powered medication management. Exams focus on practical challenges like optimizing drug formulations and patient safety protocols. The program is time-bound, requiring completion within six months, with blockchain-secured certifications awarded globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current skills and goals.",
      "Learn core topics like pharmacology, drug safety, and medication management.",
      "Explore AI's role in enhancing pharmacy practice.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world pharmacy challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in pharmacy or pharmaceutical innovation.",
      "Aligned with the latest global pharmacy standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to your skills and goals.",
      "Gain practical knowledge of pharmacy and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in pharmacy.",
      "Structured program ensures discipline with a six-month timeline.",
      "Opportunities to explore AI applications in drug development.",
      "Suitable for aspiring pharmacists and pharmaceutical researchers.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to optimize medication safety and efficacy.",
    ],
    features: [
      "Comprehensive study of pharmacy topics like pharmacology and medication safety.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on pharmacy challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and safety protocols.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in pharmacy and AI tools.",
      "Aligned with international benchmarks for pharmacy education.",
    ],
  },
  "AI Board of Nursing": {
    heading: "AI Board of Nursing",
    mainHeading: "Transform Patient Care with the AI Board of Nursing",
    summary:
      "The AI Board of Nursing is designed for individuals passionate about compassionate patient care. This program combines nursing fundamentals with AI to enhance healthcare delivery and patient outcomes. Beginning with a Training Needs Analysis (TNA), the curriculum covers patient care, medical ethics, and AI-powered monitoring systems. Exams focus on real-world nursing scenarios, ensuring preparedness for modern healthcare settings. The program is time-bound, with a six-month completion requirement, and provides blockchain-secured certifications recognized globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your skills and goals.",
      "Learn core nursing topics like patient care and medical ethics.",
      "Explore AI's role in enhancing healthcare delivery.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world nursing challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in nursing or healthcare management.",
      "Aligned with the latest global nursing standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to individual skills.",
      "Gain practical knowledge of nursing and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in patient care.",
      "Structured program ensures discipline with a six-month timeline.",
      "Opportunities to explore AI applications in patient monitoring.",
      "Suitable for aspiring nurses and healthcare managers.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to deliver effective and compassionate care.",
    ],
    features: [
      "Comprehensive study of nursing topics like patient care and ethics.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on nursing challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and healthcare delivery skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in nursing and AI tools.",
      "Aligned with international benchmarks for nursing education.",
    ],
  },
  "AI Board of Medical Research": {
    heading: "AI Board of Medical Research",
    mainHeading: "Advance Science with the AI Board of Medical Research",
    summary:
      "The AI Board of Medical Research is for individuals eager to innovate and explore in the medical field. This program combines research methodologies with Artificial Intelligence (AI) to revolutionize medical discoveries. Starting with a Training Needs Analysis (TNA), the curriculum includes clinical trials, AI-powered data analysis, and ethical research practices. Exams focus on real-world challenges like designing research studies or analyzing medical data. The program is time-bound, requiring completion within six months, and provides blockchain-secured certifications recognized globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current knowledge and goals.",
      "Learn clinical trials, data analysis, and ethical research practices.",
      "Explore AI's role in revolutionizing medical discoveries.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world medical research challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in medical research or healthcare innovation.",
      "Aligned with the latest global research standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to your skills and goals.",
      "Gain practical knowledge of medical research and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in medical research.",
      "Structured program ensures discipline with a six-month timeline.",
      "Opportunities to explore AI applications in clinical trials and data analysis.",
      "Suitable for aspiring researchers and healthcare innovators.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to conduct ethical and innovative medical research.",
    ],
    features: [
      "Comprehensive study of research topics like clinical trials and ethics.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on medical research challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and critical analysis skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in medical research and AI tools.",
      "Aligned with international benchmarks for medical research education.",
    ],
  },
  "AI Board of Public Health": {
    heading: "AI Board of Public Health",
    mainHeading: "Transform Communities with the AI Board of Public Health",
    summary:
      "The AI Board of Public Health is designed for individuals passionate about improving community health and wellness. This program blends public health principles with Artificial Intelligence (AI) to address global health challenges. Starting with a Training Needs Analysis (TNA), the curriculum covers epidemiology, health policy, and AI-powered disease tracking. Exams focus on practical applications such as designing community health programs or analyzing public health data. This time-bound program must be completed within six months, and blockchain-secured certifications are awarded upon passing.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current skills and goals.",
      "Learn public health topics like epidemiology and health policy.",
      "Explore AI's role in disease tracking and community health.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world public health challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in public health or health policy development.",
      "Aligned with the latest global public health standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to individual skills.",
      "Gain practical knowledge of public health and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in community health.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in epidemiology and health policy.",
      "Suitable for aspiring public health officials and researchers.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to improve health outcomes at a population level.",
    ],
    features: [
      "Comprehensive study of public health topics like epidemiology and policy.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on public health challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and health strategies.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in public health and AI tools.",
      "Aligned with international benchmarks for public health education.",
    ],
  },
  "AI Medical Board of Radiology": {
    heading: "AI Medical Board of Radiology",
    mainHeading: "Enhance Diagnostics with the AI Medical Board of Radiology",
    summary:
      "The AI Medical Board of Radiology is perfect for those interested in medical imaging and diagnostics. This program integrates radiology training with Artificial Intelligence (AI) to revolutionize healthcare. Starting with a Training Needs Analysis (TNA), the curriculum covers imaging techniques, AI-powered diagnostics, and patient safety. Exams focus on practical challenges like analyzing imaging results and optimizing diagnostic accuracy. This program is time-bound and must be completed within six months, with blockchain-secured certifications awarded globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current knowledge.",
      "Learn imaging techniques and AI-powered diagnostics.",
      "Explore AI's role in enhancing radiology and patient safety.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world radiology challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in radiology or diagnostic innovation.",
      "Aligned with the latest global radiology standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to individual skills.",
      "Gain practical knowledge of radiology and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in medical imaging.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in diagnostic accuracy.",
      "Suitable for aspiring radiologists and diagnostic experts.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to improve patient care with cutting-edge technology.",
    ],
    features: [
      "Comprehensive study of radiology topics like imaging techniques and AI tools.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on radiology challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and diagnostic precision.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in radiology and AI tools.",
      "Aligned with international benchmarks for radiology education.",
    ],
  },
  "AI Medical Board of Anesthesiology": {
    heading: "AI Medical Board of Anesthesiology",
    mainHeading:
      "Master Precision Care with the AI Medical Board of Anesthesiology",
    summary:
      "The AI Medical Board of Anesthesiology is designed for those interested in pain management and perioperative care. This program combines anesthesiology principles with Artificial Intelligence (AI) to enhance patient safety and outcomes. Starting with a Training Needs Analysis (TNA), the curriculum includes anesthesia techniques, AI in monitoring, and patient management. Exams focus on real-world applications like optimizing anesthesia doses and improving surgical safety. This time-bound program must be completed within six months, with blockchain-secured certifications awarded globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current skills and goals.",
      "Learn core topics like anesthesia techniques and patient safety.",
      "Explore AI's role in enhancing perioperative care.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world anesthesiology challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in anesthesiology or surgical support.",
      "Aligned with the latest global anesthesiology standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to individual skills.",
      "Gain practical knowledge of anesthesiology and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in anesthesia management.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in patient monitoring.",
      "Suitable for aspiring anesthesiologists and surgical safety experts.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to deliver precise and safe anesthetic care.",
    ],
    features: [
      "Comprehensive study of anesthesiology topics like techniques and monitoring.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on anesthesiology challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and patient safety.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in anesthesiology and AI tools.",
      "Aligned with international benchmarks for anesthesiology education.",
    ],
  },
  "AI Medical Board of Pediatrics": {
    heading: "AI Medical Board of Pediatrics",
    mainHeading:
      "Deliver Compassionate Care with the AI Medical Board of Pediatrics",
    summary:
      "The AI Medical Board of Pediatrics is perfect for those interested in healthcare for children. This program integrates pediatric training with Artificial Intelligence (AI) to improve diagnosis and treatment for young patients. Starting with a Training Needs Analysis (TNA), the curriculum covers child health, growth monitoring, and AI-powered diagnostics. Exams focus on practical challenges like treating childhood illnesses and designing care plans. This time-bound program must be completed within six months, with blockchain-secured certifications awarded globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current knowledge.",
      "Learn pediatric care, growth monitoring, and AI diagnostics.",
      "Explore AI's role in enhancing child healthcare.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world pediatric challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in pediatrics or child healthcare innovation.",
      "Aligned with the latest global pediatric standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to individual skills.",
      "Gain practical knowledge of pediatric healthcare and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in child health.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in pediatric diagnostics.",
      "Suitable for aspiring pediatricians and child healthcare specialists.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to deliver compassionate and effective care for children.",
    ],
    features: [
      "Comprehensive study of pediatric topics like child health and diagnostics.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on pediatric challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and child healthcare skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in pediatrics and AI tools.",
      "Aligned with international benchmarks for pediatric education.",
    ],
  },
  "AI Medical Board of Dermatology": {
    heading: "AI Medical Board of Dermatology",
    mainHeading: "Enhance Skin Health with the AI Medical Board of Dermatology",
    summary:
      "The AI Medical Board of Dermatology is for those passionate about skincare and dermatological treatments. This program combines dermatology education with Artificial Intelligence (AI) to revolutionize diagnostics and treatment. Starting with a Training Needs Analysis (TNA), the curriculum covers skin diseases, cosmetic treatments, and AI-powered diagnostics. Exams focus on practical challenges like identifying skin conditions and designing treatment plans. This time-bound program must be completed within six months, with blockchain-secured certifications awarded globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current knowledge and goals.",
      "Learn dermatology topics like skin diseases and cosmetic treatments.",
      "Explore AI's role in enhancing dermatological diagnostics.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world dermatology challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in dermatology or skincare innovation.",
      "Aligned with the latest global dermatology standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to individual skills.",
      "Gain practical knowledge of dermatology and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in skin health.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in skincare and diagnostics.",
      "Suitable for aspiring dermatologists and skincare experts.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to deliver effective and innovative skincare solutions.",
    ],
    features: [
      "Comprehensive study of dermatology topics like skin diseases and treatments.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on dermatology challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and diagnostic skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in dermatology and AI tools.",
      "Aligned with international benchmarks for dermatology education.",
    ],
  },
  "AI Medical Board of Psychiatry": {
    heading: "AI Medical Board of Psychiatry",
    mainHeading: "Master Mental Health with the AI Medical Board of Psychiatry",
    summary:
      "The AI Medical Board of Psychiatry is designed for individuals who are passionate about understanding and improving mental health. This program combines psychiatry fundamentals with Artificial Intelligence (AI) to enhance diagnostics, treatment planning, and patient care. Starting with a Training Needs Analysis (TNA), the curriculum includes topics like mental health disorders, AI-driven therapy tools, and patient communication strategies. Exams focus on real-world applications, such as designing treatment plans and using AI for mental health support. This program is time-bound, requiring completion within six months, and offers blockchain-secured certifications recognized globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current knowledge and goals.",
      "Learn about mental health disorders and patient care strategies.",
      "Explore AI's role in enhancing psychiatric diagnostics and treatments.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world mental health challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in psychiatry or mental health innovation.",
      "Aligned with the latest global psychiatric standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to individual skills.",
      "Gain practical knowledge of psychiatry and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in mental health.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in therapy and diagnostics.",
      "Suitable for aspiring psychiatrists and mental health specialists.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to deliver effective and compassionate mental health care.",
    ],
    features: [
      "Comprehensive study of psychiatry topics like disorders and treatments.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on psychiatric challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and treatment strategies.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in psychiatry and AI tools.",
      "Aligned with international benchmarks for psychiatric education.",
    ],
  },
  "AI Medical Board of Cardiology": {
    heading: "AI Medical Board of Cardiology",
    mainHeading: "Advance Heart Care with the AI Medical Board of Cardiology",
    summary:
      "The AI Medical Board of Cardiology is perfect for those who want to specialize in heart health and treatment. This program integrates cardiology training with Artificial Intelligence (AI) to improve patient outcomes and treatment efficiency. Starting with a Training Needs Analysis (TNA), the curriculum covers heart diseases, AI-driven diagnostics, and cardiac care management. Exams focus on practical applications, such as diagnosing heart conditions and designing effective treatment plans. This time-bound program must be completed within six months, with blockchain-secured certifications awarded globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current skills and goals.",
      "Learn about heart diseases, diagnostics, and care strategies.",
      "Explore AI's role in enhancing cardiac care and treatments.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world cardiology challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in cardiology or cardiac research.",
      "Aligned with the latest global cardiology standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to individual skills.",
      "Gain practical knowledge of cardiology and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in heart health.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in cardiac diagnostics.",
      "Suitable for aspiring cardiologists and heart health experts.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to improve patient care with advanced AI-driven tools.",
    ],
    features: [
      "Comprehensive study of cardiology topics like diagnostics and treatment.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on cardiac challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and diagnostic accuracy.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in cardiology and AI tools.",
      "Aligned with international benchmarks for cardiology education.",
    ],
  },
  "AI Medical Board of Neurology": {
    heading: "AI Medical Board of Neurology",
    mainHeading: "Understand the Brain with the AI Medical Board of Neurology",
    summary:
      "The AI Medical Board of Neurology is for those passionate about understanding the complexities of the human brain. This program combines neurology fundamentals with Artificial Intelligence (AI) to enhance diagnostics, treatments, and patient care. Starting with a Training Needs Analysis (TNA), the curriculum includes topics like neurological disorders, AI-powered brain imaging, and patient rehabilitation. Exams focus on real-world applications such as diagnosing neurological conditions and designing treatment protocols. This program is time-bound, requiring completion within six months, and offers blockchain-secured certifications recognized globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current knowledge and goals.",
      "Learn about neurological disorders and brain imaging techniques.",
      "Explore AI's role in neurology and patient rehabilitation.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world neurological challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in neurology or neurological research.",
      "Aligned with the latest global neurology standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to individual skills.",
      "Gain practical knowledge of neurology and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in brain health.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in neurology diagnostics.",
      "Suitable for aspiring neurologists and brain health specialists.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to design effective treatment plans for neurological disorders.",
    ],
    features: [
      "Comprehensive study of neurology topics like disorders and imaging.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on neurological challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and critical thinking.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in neurology and AI tools.",
      "Aligned with international benchmarks for neurology education.",
    ],
  },
  "AI Dental Board of Examination": {
    heading: "AI Dental Board of Examination",
    mainHeading: "Redefine Dental Care with the AI Dental Board of Examination",
    summary:
      "The AI Dental Board of Examination is for anyone interested in modern dentistry and oral health. This program combines dental education with Artificial Intelligence (AI) to improve patient outcomes and streamline dental procedures. Starting with a Training Needs Analysis (TNA), the curriculum includes oral health, AI-powered diagnostics, and advanced dental treatments. Exams focus on practical applications such as diagnosing dental conditions and optimizing treatment plans. This time-bound program must be completed within six months, with blockchain-secured certifications awarded globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current knowledge and goals.",
      "Learn oral health, advanced treatments, and patient care strategies.",
      "Explore AI's role in enhancing dental diagnostics and procedures.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world dental challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in modern dentistry or oral health innovation.",
      "Aligned with the latest global dental standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to individual skills.",
      "Gain practical knowledge of dentistry and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in oral health.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in dental diagnostics.",
      "Suitable for aspiring dentists and oral health specialists.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to deliver effective and innovative dental care.",
    ],
    features: [
      "Comprehensive study of dental topics like oral health and treatments.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on dental challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and treatment skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in dentistry and AI tools.",
      "Aligned with international benchmarks for dental education.",
    ],
  },
  "AI Veterinary Board of Examination": {
    heading: "AI Veterinary Board of Examination",
    mainHeading:
      "Transform Animal Care with the AI Veterinary Board of Examination",
    summary:
      "The AI Veterinary Board of Examination is tailored for those interested in animal health and veterinary care. This program integrates veterinary science with Artificial Intelligence (AI) to enhance diagnostics, treatments, and animal welfare. Starting with a Training Needs Analysis (TNA), the curriculum covers topics like animal diseases, AI-powered diagnostics, and treatment planning. Exams focus on practical applications such as diagnosing animal conditions and improving care strategies. This time-bound program must be completed within six months, with blockchain-secured certifications awarded globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current knowledge and goals.",
      "Learn about animal diseases, diagnostics, and care strategies.",
      "Explore AI's role in enhancing veterinary care and treatments.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world veterinary challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in veterinary science or animal welfare.",
      "Aligned with the latest global veterinary standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to individual skills.",
      "Gain practical knowledge of veterinary care and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in animal health.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in veterinary diagnostics.",
      "Suitable for aspiring veterinarians and animal welfare specialists.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to deliver effective and innovative animal care.",
    ],
    features: [
      "Comprehensive study of veterinary topics like diseases and diagnostics.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on veterinary challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and treatment strategies.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in veterinary science and AI tools.",
      "Aligned with international benchmarks for veterinary education.",
    ],
  },
  "AI Medical Board of Oncology": {
    heading: "AI Medical Board of Oncology",
    mainHeading: "Advance Cancer Care with the AI Medical Board of Oncology",
    summary:
      "The AI Medical Board of Oncology is designed for individuals passionate about understanding and treating cancer. This program combines oncology education with Artificial Intelligence (AI) to improve cancer diagnostics, treatments, and patient outcomes. Starting with a Training Needs Analysis (TNA), the curriculum includes cancer biology, AI-powered imaging, and personalized treatment planning. Exams focus on real-world applications such as designing treatment protocols and analyzing cancer data. This time-bound program must be completed within six months, with blockchain-secured certifications awarded globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your knowledge and goals.",
      "Learn about cancer biology, diagnostics, and treatments.",
      "Explore AI's role in enhancing oncology care.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world oncology challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in oncology or cancer research.",
      "Aligned with the latest global oncology standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to individual skills.",
      "Gain practical knowledge of oncology and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in cancer care.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in cancer diagnostics.",
      "Suitable for aspiring oncologists and cancer researchers.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to improve patient outcomes with advanced AI-driven tools.",
    ],
    features: [
      "Comprehensive study of oncology topics like diagnostics and treatment.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on oncology challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and treatment accuracy.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in oncology and AI tools.",
      "Aligned with international benchmarks for oncology education.",
    ],
  },
  "AI Board of Reproductive Health": {
    heading: "AI Board of Reproductive Health",
    mainHeading: "Empower Life with the AI Board of Reproductive Health",
    summary:
      "The AI Board of Reproductive Health focuses on enhancing knowledge about human reproduction, fertility, and maternal care. This program integrates core reproductive health education with Artificial Intelligence (AI) to improve diagnostics and treatments. Starting with a Training Needs Analysis (TNA), the curriculum includes fertility management, AI-powered monitoring, and maternal health. Exams focus on practical challenges such as designing care plans and optimizing fertility treatments. This time-bound program must be completed within six months, with blockchain-secured certifications awarded globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your knowledge and goals.",
      "Learn about reproductive health, fertility management, and maternal care.",
      "Explore AI's role in improving reproductive health outcomes.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world reproductive health challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in reproductive health or fertility innovation.",
      "Aligned with the latest global reproductive health standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to individual skills.",
      "Gain practical knowledge of reproductive health and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in fertility care.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in reproductive health.",
      "Suitable for aspiring reproductive health specialists.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to deliver effective maternal and reproductive care.",
    ],
    features: [
      "Comprehensive study of reproductive health topics like fertility and maternal care.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on reproductive health challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and patient care strategies.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in reproductive health and AI tools.",
      "Aligned with international benchmarks for reproductive health education.",
    ],
  },
  "AI Board of Geriatrics": {
    heading: "AI Board of Geriatrics",
    mainHeading: "Enhance Elder Care with the AI Board of Geriatrics",
    summary:
      "The AI Board of Geriatrics is for those dedicated to improving the health and quality of life for older adults. This program combines geriatrics education with Artificial Intelligence (AI) to develop innovative care strategies. Starting with a Training Needs Analysis (TNA), the curriculum includes aging-related diseases, AI-powered monitoring, and senior care management. Exams focus on practical applications such as optimizing treatment plans and improving elder care systems. This time-bound program must be completed within six months, with blockchain-secured certifications awarded globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current knowledge and goals.",
      "Learn about aging diseases, elder care, and patient management.",
      "Explore AI's role in enhancing senior healthcare.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world geriatric healthcare challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in geriatrics or senior care management.",
      "Aligned with the latest global geriatrics standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to individual skills.",
      "Gain practical knowledge of geriatrics and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in elder care.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in senior healthcare.",
      "Suitable for aspiring geriatricians and senior care specialists.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to improve elder care systems and treatment outcomes.",
    ],
    features: [
      "Comprehensive study of geriatrics topics like diseases and senior care.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on geriatric healthcare challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and elder care skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in geriatrics and AI tools.",
      "Aligned with international benchmarks for geriatric education.",
    ],
  },
  "AI Board of Physiotherapy": {
    heading: "AI Board of Physiotherapy",
    mainHeading: "Empower Rehabilitation with the AI Board of Physiotherapy",
    summary:
      "The AI Board of Physiotherapy is for individuals who are passionate about helping others recover from injuries and improve mobility. This program integrates physiotherapy education with Artificial Intelligence (AI) to enhance rehabilitation techniques and patient outcomes. Starting with a Training Needs Analysis (TNA), the curriculum includes biomechanics, AI-driven therapy tools, and patient recovery strategies. Exams focus on real-world applications like designing therapy programs and optimizing recovery plans. This program is time-bound, requiring completion within six months, and offers blockchain-secured certifications recognized globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current knowledge and goals.",
      "Learn about rehabilitation techniques and mobility improvement.",
      "Explore AI's role in physiotherapy and patient recovery.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world physiotherapy challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in physiotherapy or rehabilitation innovation.",
      "Aligned with the latest global physiotherapy standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to individual skills.",
      "Gain practical knowledge of physiotherapy and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in rehabilitation.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in mobility and recovery.",
      "Suitable for aspiring physiotherapists and rehabilitation experts.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to optimize therapy and improve patient mobility.",
    ],
    features: [
      "Comprehensive study of physiotherapy topics like biomechanics and therapy tools.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on physiotherapy challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and recovery strategies.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in physiotherapy and AI tools.",
      "Aligned with international benchmarks for physiotherapy education.",
    ],
  },
  "AI Board of Paramedical Sciences": {
    heading: "AI Board of Paramedical Sciences",
    mainHeading:
      "Enhance Emergency Care with the AI Board of Paramedical Sciences",
    summary:
      "The AI Board of Paramedical Sciences prepares individuals for emergency care and medical assistance. This program combines paramedical training with Artificial Intelligence (AI) to optimize emergency response and patient support. Starting with a Training Needs Analysis (TNA), the curriculum includes emergency care, AI-powered monitoring, and patient handling techniques. Exams focus on practical challenges like emergency response planning and improving care strategies. This program is time-bound, requiring completion within six months, with blockchain-secured certifications awarded globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current knowledge and goals.",
      "Learn emergency care, patient handling, and medical assistance techniques.",
      "Explore AI's role in optimizing paramedical practices.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world paramedical challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in paramedical sciences or emergency care.",
      "Aligned with the latest global paramedical standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to individual skills.",
      "Gain practical knowledge of paramedical sciences and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in emergency care.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in patient monitoring.",
      "Suitable for aspiring paramedics and emergency care experts.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to deliver effective and compassionate medical support.",
    ],
    features: [
      "Comprehensive study of paramedical topics like emergency response and AI tools.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on paramedical challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and emergency handling skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in paramedical sciences and AI tools.",
      "Aligned with international benchmarks for paramedical education.",
    ],
  },
  "AI Board of Alternative Medicine": {
    heading: "AI Board of Alternative Medicine",
    mainHeading:
      "Explore Holistic Healing with the AI Board of Alternative Medicine",
    summary:
      "The AI Board of Alternative Medicine is for those passionate about natural and holistic healing approaches. This program combines alternative medicine techniques with Artificial Intelligence (AI) to advance diagnostics and treatment planning. Starting with a Training Needs Analysis (TNA), the curriculum includes herbal medicine, acupuncture, homeopathy, and AI-powered treatment methodologies. Exams focus on practical applications like designing holistic treatment plans and understanding patient care strategies. The program must be completed within six months, and blockchain-secured certifications are globally recognized.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your knowledge and goals.",
      "Learn about herbal medicine, acupuncture, and homeopathy.",
      "Explore AI's role in enhancing alternative treatment strategies.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world alternative medicine challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in alternative medicine or holistic health.",
      "Aligned with the latest global alternative medicine standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to individual skills.",
      "Gain practical knowledge of alternative medicine and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in holistic health.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in natural healing.",
      "Suitable for aspiring alternative medicine practitioners.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to deliver safe and effective holistic treatments.",
    ],
    features: [
      "Comprehensive study of alternative medicine techniques and AI tools.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on holistic health challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and treatment skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in alternative medicine and AI tools.",
      "Aligned with international benchmarks for alternative medicine education.",
    ],
  },
  "AI Board of Nutrition and Dietetics": {
    heading: "AI Board of Nutrition and Dietetics",
    mainHeading: "Optimize Health with the AI Board of Nutrition and Dietetics",
    summary:
      "The AI Board of Nutrition and Dietetics is for those interested in understanding and improving human nutrition. This program integrates nutrition science with Artificial Intelligence (AI) to create personalized diet plans and innovative health solutions. Starting with a Training Needs Analysis (TNA), the curriculum covers dietary management, AI-powered nutrition tracking, and public health strategies. Exams focus on real-world applications like designing nutrition plans and addressing health issues through diet. The program must be completed within six months, with blockchain-secured certifications recognized globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your knowledge and goals.",
      "Learn about dietary management and nutrition science.",
      "Explore AI's role in enhancing health through personalized diets.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world nutrition and health challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in dietetics or public health nutrition.",
      "Aligned with the latest global nutrition standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to individual skills.",
      "Gain practical knowledge of nutrition and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in dietary planning.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in nutrition science.",
      "Suitable for aspiring dietitians and nutritionists.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to create effective and innovative nutrition solutions.",
    ],
    features: [
      "Comprehensive study of nutrition topics like dietary planning and AI tools.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on nutrition challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and nutrition strategies.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in nutrition science and AI tools.",
      "Aligned with international benchmarks for nutrition education.",
    ],
  },
  "AI Board of Forensic Medicine": {
    heading: "AI Board of Forensic Medicine",
    mainHeading: "Uncover Truths with the AI Board of Forensic Medicine",
    summary:
      "The AI Board of Forensic Medicine is tailored for individuals fascinated by forensic science and criminal investigations. This program integrates forensic medicine with Artificial Intelligence (AI) to enhance crime-solving techniques and evidence analysis. Starting with a Training Needs Analysis (TNA), the curriculum includes forensic pathology, AI-driven evidence analysis, and legal frameworks. Exams focus on practical challenges such as crime scene investigation and evidence interpretation. The program must be completed within six months, with blockchain-secured certifications awarded globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your knowledge and goals.",
      "Learn about forensic pathology, crime investigation, and legal frameworks.",
      "Explore AI's role in enhancing forensic analysis.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world forensic challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in forensic science or criminal investigation.",
      "Aligned with the latest global forensic standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to individual skills.",
      "Gain practical knowledge of forensic medicine and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in forensic science.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in evidence analysis.",
      "Suitable for aspiring forensic scientists and investigators.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to uncover truths and solve crimes using AI tools.",
    ],
    features: [
      "Comprehensive study of forensic medicine topics like pathology and AI tools.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on forensic challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and investigative skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in forensic science and AI tools.",
      "Aligned with international benchmarks for forensic medicine education.",
    ],
  },
  "AI Board of Genetic Counseling": {
    heading: "AI Board of Genetic Counseling",
    mainHeading: "Empower Lives with the AI Board of Genetic Counseling",
    summary:
      "The AI Board of Genetic Counseling is for those passionate about understanding genetic information and guiding individuals through complex genetic decisions. This program integrates genetic science with Artificial Intelligence (AI) to enhance diagnostics and counseling processes. Starting with a Training Needs Analysis (TNA), the curriculum covers genetic testing, AI-driven data interpretation, and counseling strategies. Exams focus on practical applications such as interpreting genetic data and providing patient guidance. The program must be completed within six months, with blockchain-secured certifications globally recognized.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your knowledge and goals.",
      "Learn about genetic testing, data interpretation, and counseling techniques.",
      "Explore AI's role in advancing genetic counseling practices.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world genetic counseling challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in genetic counseling or genetic research.",
      "Aligned with the latest global genetic counseling standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to individual skills.",
      "Gain practical knowledge of genetic counseling and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in genetic decision-making.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in genetic diagnostics.",
      "Suitable for aspiring genetic counselors and researchers.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to guide individuals through complex genetic information.",
    ],
    features: [
      "Comprehensive study of genetic counseling topics like testing and AI tools.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on genetic challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and counseling strategies.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in genetic counseling and AI tools.",
      "Aligned with international benchmarks for genetic counseling education.",
    ],
  },
  "AI Business Board of Marketing": {
    heading: "AI Business Board of Marketing",
    mainHeading:
      "Transform Marketing Strategies with the AI Business Board of Marketing",
    summary:
      "The AI Business Board of Marketing is perfect for anyone looking to understand the art and science of marketing. This program integrates modern marketing techniques with Artificial Intelligence (AI) to craft innovative strategies and campaigns. Starting with a Training Needs Analysis (TNA), the curriculum covers market research, AI-driven customer insights, and digital marketing strategies. Exams focus on real-world applications like designing campaigns and optimizing marketing performance. The program must be completed within six months, and certifications are blockchain-secured and globally recognized.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current knowledge and goals.",
      "Learn about digital marketing, market research, and customer insights.",
      "Explore AI's role in enhancing marketing efficiency.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world marketing challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in marketing and brand management.",
      "Aligned with the latest global marketing standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to individual skills.",
      "Gain practical knowledge of marketing and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in marketing.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in marketing strategies.",
      "Suitable for aspiring marketers and business strategists.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to create impactful and data-driven marketing campaigns.",
    ],
    features: [
      "Comprehensive study of marketing topics like digital strategies and AI tools.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on marketing challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and strategic thinking.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in marketing and AI tools.",
      "Aligned with international benchmarks for marketing education.",
    ],
  },
  "AI Business Board of Human Resource Management": {
    heading: "AI Business Board of Human Resource Management",
    mainHeading:
      "Revolutionize HR Practices with the AI Business Board of Human Resource Management",
    summary:
      "The AI Business Board of Human Resource Management focuses on modernizing HR practices with the help of Artificial Intelligence (AI). This program covers workforce planning, AI-powered recruitment, and employee engagement strategies. Starting with a Training Needs Analysis (TNA), the curriculum is tailored to match individual goals. Exams focus on real-world challenges like creating HR strategies and optimizing team performance. The program must be completed within six months, and certifications are blockchain-secured for global recognition.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current knowledge and goals.",
      "Learn about workforce planning, recruitment, and employee engagement.",
      "Explore AI's role in transforming HR practices.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world HR challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in HR management and consulting.",
      "Aligned with the latest global HR standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to individual skills.",
      "Gain practical knowledge of HR management and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in workforce management.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in HR analytics.",
      "Suitable for aspiring HR professionals and managers.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to enhance organizational productivity with modern HR strategies.",
    ],
    features: [
      "Comprehensive study of HR topics like recruitment and employee engagement.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on HR challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and HR strategies.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in HR practices and AI tools.",
      "Aligned with international benchmarks for HR education.",
    ],
  },
  "AI Business Board of Finance": {
    heading: "AI Business Board of Finance",
    mainHeading:
      "Master Financial Innovation with the AI Business Board of Finance",
    summary:
      "The AI Business Board of Finance is perfect for individuals seeking to excel in financial planning and management. This program integrates finance education with Artificial Intelligence (AI) to enhance decision-making and analytics. Starting with a Training Needs Analysis (TNA), the curriculum covers financial modeling, AI-driven investment strategies, and risk management. Exams focus on real-world applications like portfolio optimization and financial forecasting. The program is time-bound, requiring completion within six months, with blockchain-secured certifications recognized worldwide.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current knowledge and goals.",
      "Learn about financial modeling, investment strategies, and risk management.",
      "Explore AI's role in revolutionizing finance and analytics.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world financial challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in finance and investment management.",
      "Aligned with the latest global financial standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to individual skills.",
      "Gain practical knowledge of finance and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in financial analysis.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in financial modeling.",
      "Suitable for aspiring financial analysts and managers.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to design innovative financial solutions and strategies.",
    ],
    features: [
      "Comprehensive study of finance topics like risk management and AI tools.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on financial challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and financial expertise.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in finance and AI tools.",
      "Aligned with international benchmarks for financial education.",
    ],
  },
  "AI Business Board of Accounting": {
    heading: "AI Business Board of Accounting",
    mainHeading:
      "Modernize Accounting Practices with the AI Business Board of Accounting",
    summary:
      "The AI Business Board of Accounting is tailored for those passionate about accurate financial reporting and auditing. This program integrates accounting principles with Artificial Intelligence (AI) to streamline processes and ensure compliance. Starting with a Training Needs Analysis (TNA), the curriculum covers bookkeeping, AI-powered auditing tools, and financial analysis. Exams focus on practical tasks like preparing reports and auditing processes. The program must be completed within six months, and certifications are blockchain-secured and globally recognized.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current knowledge and goals.",
      "Learn about bookkeeping, auditing, and financial compliance.",
      "Explore AI's role in automating accounting processes.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world accounting challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in accounting and auditing.",
      "Aligned with the latest global accounting standards.",
    ],
    benefits: [
      "Accessible to candidates of all ages and educational backgrounds.",
      "Personalized learning paths tailored to individual skills.",
      "Gain practical knowledge of accounting and AI integration.",
      "Certifications are globally trusted and blockchain-secured.",
      "Develop real-world problem-solving skills in accounting.",
      "Structured program ensures focus with a six-month timeline.",
      "Opportunities to explore AI applications in financial analysis.",
      "Suitable for aspiring accountants and auditors.",
      "Accessible exams online or at physical centers worldwide.",
      "Learn to improve accuracy and efficiency in accounting processes.",
    ],
    features: [
      "Comprehensive study of accounting topics like bookkeeping and auditing.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on accounting challenges and AI applications.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and financial accuracy.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in accounting and AI tools.",
      "Aligned with international benchmarks for accounting education.",
    ],
  },
  "AI Board of Entrepreneurship": {
    heading: "AI Board of Entrepreneurship",
    mainHeading:
      "Empower Your Business Ideas with the AI Board of Entrepreneurship",
    summary:
      "The AI Board of Entrepreneurship is for anyone with a dream to start their own business and make it successful. This program integrates entrepreneurial strategies with Artificial Intelligence (AI) to help you create, manage, and scale your business. Starting with a Training Needs Analysis (TNA), the curriculum covers business planning, AI-powered market analysis, and innovation strategies. Exams focus on real-world applications like pitching business ideas and solving startup challenges. The program must be completed within six months, with blockchain-secured certifications awarded globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your knowledge and goals.",
      "Learn how to build and scale your business effectively.",
      "Explore AI's role in entrepreneurship and innovation.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world entrepreneurial challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in entrepreneurship and business innovation.",
      "Aligned with the latest global entrepreneurship standards.",
    ],
    benefits: [
      "Gain skills to start and grow your business.",
      "Access personalized learning tailored to your goals.",
      "Learn to integrate AI into your business strategies.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Develop problem-solving skills for real-world challenges.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI applications in market analysis and product development.",
      "Suitable for aspiring entrepreneurs and innovators.",
      "Access exams online or at physical centers worldwide.",
      "Turn your business ideas into successful ventures.",
    ],
    features: [
      "Comprehensive study of entrepreneurship and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on startup strategies and innovation.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical business knowledge and innovation.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in entrepreneurship.",
      "Aligned with international benchmarks for entrepreneurial education.",
    ],
  },
  "AI Business Board of Operations Management": {
    heading: "AI Business Board of Operations Management",
    mainHeading:
      "Streamline Business Processes with the AI Business Board of Operations Management",
    summary:
      "The AI Business Board of Operations Management helps individuals master the skills required to optimize business operations. This program integrates operations management with Artificial Intelligence (AI) to improve efficiency and productivity. Starting with a Training Needs Analysis (TNA), the curriculum includes process optimization, AI-driven workflow analysis, and project management. Exams focus on practical applications like streamlining operations and solving workflow challenges. This program must be completed within six months, with blockchain-secured certifications awarded globally.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current knowledge and goals.",
      "Learn about process optimization and workflow efficiency.",
      "Explore AI's role in enhancing operations management.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world operational challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in operations management and process improvement.",
      "Aligned with the latest global operations standards.",
    ],
    benefits: [
      "Gain skills to optimize business operations.",
      "Access personalized learning tailored to your goals.",
      "Learn to integrate AI into operational strategies.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Develop problem-solving skills for real-world workflow challenges.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI applications in process efficiency and management.",
      "Suitable for aspiring operations managers and process analysts.",
      "Access exams online or at physical centers worldwide.",
      "Improve productivity and streamline workflows effectively.",
    ],
    features: [
      "Comprehensive study of operations management and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on workflow challenges and optimization.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical operational knowledge and strategies.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in operations management.",
      "Aligned with international benchmarks for operational education.",
    ],
  },
  "AI Business Board of Supply Chain Management": {
    heading: "AI Business Board of Supply Chain Management",
    mainHeading:
      "Optimize Logistics with the AI Business Board of Supply Chain Management",
    summary:
      "The AI Business Board of Supply Chain Management is for those who want to understand the complexities of supply chains and logistics. This program integrates supply chain strategies with Artificial Intelligence (AI) to improve efficiency and resilience. Starting with a Training Needs Analysis (TNA), the curriculum covers inventory management, AI-driven logistics, and global trade. Exams focus on real-world applications like streamlining supply chain operations and addressing logistics challenges. This program is time-bound, requiring completion within six months, with blockchain-secured certifications globally recognized.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current knowledge and goals.",
      "Learn about logistics, inventory management, and global trade.",
      "Explore AI's role in enhancing supply chain efficiency.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world supply chain challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in logistics and supply chain management.",
      "Aligned with the latest global supply chain standards.",
    ],
    benefits: [
      "Gain skills to optimize supply chain operations.",
      "Access personalized learning tailored to your goals.",
      "Learn to integrate AI into logistics and trade strategies.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Develop problem-solving skills for real-world supply chain challenges.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI applications in logistics and inventory management.",
      "Suitable for aspiring supply chain managers and analysts.",
      "Access exams online or at physical centers worldwide.",
      "Improve efficiency and reduce costs in supply chain processes.",
    ],
    features: [
      "Comprehensive study of supply chain management and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on logistics challenges and optimization.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and logistics strategies.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in supply chain management.",
      "Aligned with international benchmarks for supply chain education.",
    ],
  },
  "AI Board of Real Estate Management": {
    heading: "AI Board of Real Estate Management",
    mainHeading:
      "Transform Property Management with the AI Board of Real Estate Management",
    summary:
      "The AI Board of Real Estate Management is ideal for individuals interested in property management and real estate investment. This program integrates real estate education with Artificial Intelligence (AI) to streamline property analysis and investment strategies. Starting with a Training Needs Analysis (TNA), the curriculum covers property valuation, AI-driven market analysis, and asset management. Exams focus on real-world applications like evaluating properties and developing investment plans. The program is time-bound, requiring completion within six months, with blockchain-secured certifications globally recognized.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current knowledge and goals.",
      "Learn about property management, valuation, and asset strategies.",
      "Explore AI's role in enhancing real estate investments.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world real estate challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in property management and real estate investment.",
      "Aligned with the latest global real estate standards.",
    ],
    benefits: [
      "Gain skills to manage real estate portfolios effectively.",
      "Access personalized learning tailored to your goals.",
      "Learn to integrate AI into property management strategies.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Develop problem-solving skills for real-world property challenges.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI applications in real estate market analysis.",
      "Suitable for aspiring property managers and real estate investors.",
      "Access exams online or at physical centers worldwide.",
      "Improve property valuation and investment strategies effectively.",
    ],
    features: [
      "Comprehensive study of real estate management and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on property challenges and investment strategies.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and real estate expertise.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in real estate management.",
      "Aligned with international benchmarks for real estate education.",
    ],
  },
  "AI Board of E-Commerce Management": {
    heading: "AI Board of E-Commerce Management",
    mainHeading:
      "Lead Digital Business with the AI Board of E-Commerce Management",
    summary:
      "The AI Board of E-Commerce Management is for those who want to excel in managing online businesses and marketplaces. This program integrates e-commerce strategies with Artificial Intelligence (AI) to improve digital sales and customer experience. Starting with a Training Needs Analysis (TNA), the curriculum covers digital marketing, AI-driven customer insights, and supply chain optimization. Exams focus on real-world applications like launching e-commerce campaigns and streamlining online operations. This program must be completed within six months, with blockchain-secured certifications globally recognized.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current knowledge and goals.",
      "Learn about digital marketing, customer insights, and online operations.",
      "Explore AI's role in enhancing e-commerce efficiency.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world e-commerce challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in e-commerce management and digital marketing.",
      "Aligned with the latest global e-commerce standards.",
    ],
    benefits: [
      "Gain skills to manage and grow online businesses effectively.",
      "Access personalized learning tailored to your goals.",
      "Learn to integrate AI into e-commerce operations and marketing.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Develop problem-solving skills for real-world e-commerce challenges.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI applications in customer insights and sales strategies.",
      "Suitable for aspiring e-commerce managers and marketers.",
      "Access exams online or at physical centers worldwide.",
      "Optimize online operations and improve customer experiences.",
    ],
    features: [
      "Comprehensive study of e-commerce management and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on digital business challenges and solutions.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge and e-commerce strategies.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in e-commerce management.",
      "Aligned with international benchmarks for e-commerce education.",
    ],
  },
  "AI Board of Leadership Development": {
    heading: "AI Board of Leadership Development",
    mainHeading:
      "Develop Visionary Leaders with the AI Board of Leadership Development",
    summary:
      "The AI Board of Leadership Development is designed to help individuals unlock their leadership potential. This program combines leadership principles with Artificial Intelligence (AI) to enhance decision-making, communication, and team-building skills. Starting with a Training Needs Analysis (TNA), the curriculum includes AI-powered leadership tools, conflict resolution, and strategic planning. Exams focus on practical applications like managing teams and leading projects. This program is time-bound, requiring completion within six months, with blockchain-secured certifications globally recognized.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to individual leadership goals.",
      "Learn decision-making, communication, and team-building strategies.",
      "Explore AI's role in enhancing leadership effectiveness.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world leadership challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for leadership roles in any industry.",
      "Aligned with the latest global leadership standards.",
    ],
    benefits: [
      "Gain essential leadership skills for real-world applications.",
      "Access personalized learning tailored to your leadership goals.",
      "Learn to integrate AI into leadership decision-making.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Develop team-building and strategic planning expertise.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for conflict resolution and team management.",
      "Suitable for aspiring leaders and executives.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful leadership roles in diverse sectors.",
    ],
    features: [
      "Comprehensive study of leadership principles and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on leadership challenges and AI tools.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical leadership knowledge and strategies.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in leadership education.",
      "Aligned with international benchmarks for leadership development.",
    ],
  },
  "AI Board of Strategic Management": {
    heading: "AI Board of Strategic Management",
    mainHeading:
      "Master Business Strategies with the AI Board of Strategic Management",
    summary:
      "The AI Board of Strategic Management is ideal for individuals looking to lead organizations to success through effective strategies. This program integrates strategic management principles with Artificial Intelligence (AI) to improve business planning and execution. Starting with a Training Needs Analysis (TNA), the curriculum covers market analysis, AI-driven decision-making, and organizational development. Exams focus on practical applications like crafting business strategies and solving management challenges. This program must be completed within six months, with blockchain-secured certifications globally recognized.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to individual strategic goals.",
      "Learn about business planning, market analysis, and decision-making.",
      "Explore AI's role in optimizing strategic management.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world strategic challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in business strategy and management.",
      "Aligned with the latest global strategic standards.",
    ],
    benefits: [
      "Gain skills to craft and implement effective business strategies.",
      "Access personalized learning tailored to your strategic goals.",
      "Learn to integrate AI into business planning and analysis.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Develop problem-solving skills for real-world strategic challenges.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI applications in organizational development and leadership.",
      "Suitable for aspiring strategists and business executives.",
      "Access exams online or at physical centers worldwide.",
      "Learn to navigate complex business environments with AI support.",
    ],
    features: [
      "Comprehensive study of strategic management and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on strategic challenges and business solutions.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical strategic knowledge and skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in strategic education.",
      "Aligned with international benchmarks for strategic management.",
    ],
  },
  "AI Board of Project Management": {
    heading: "AI Board of Project Management",
    mainHeading:
      "Lead Projects Efficiently with the AI Board of Project Management",
    summary:
      "The AI Board of Project Management is perfect for those who want to excel in planning and executing projects. This program integrates project management principles with Artificial Intelligence (AI) to enhance efficiency and productivity. Starting with a Training Needs Analysis (TNA), the curriculum covers project planning, AI-driven tools, and risk management. Exams focus on real-world applications like managing projects and overcoming challenges. This program is time-bound, requiring completion within six months, with blockchain-secured certifications globally recognized.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your project management goals.",
      "Learn about planning, risk management, and project execution.",
      "Explore AI's role in streamlining project management.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world project challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in project management and coordination.",
      "Aligned with the latest global project management standards.",
    ],
    benefits: [
      "Gain skills to manage projects efficiently and effectively.",
      "Access personalized learning tailored to your goals.",
      "Learn to integrate AI into project planning and execution.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Develop problem-solving skills for real-world project challenges.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for risk management and resource allocation.",
      "Suitable for aspiring project managers and coordinators.",
      "Access exams online or at physical centers worldwide.",
      "Prepare to lead successful projects in diverse industries.",
    ],
    features: [
      "Comprehensive study of project management and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on project challenges and management solutions.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical project management skills.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in project management education.",
      "Aligned with international benchmarks for project management.",
    ],
  },
  "AI Board of Microfinance": {
    heading: "AI Board of Microfinance",
    mainHeading: "Transform Lives with the AI Board of Microfinance",
    summary:
      "The AI Board of Microfinance is for individuals dedicated to empowering communities through financial inclusion. This program integrates microfinance principles with Artificial Intelligence (AI) to improve financial planning and resource allocation. Starting with a Training Needs Analysis (TNA), the curriculum covers microcredit systems, AI-driven financial tools, and impact measurement. Exams focus on practical applications like designing microfinance models and addressing challenges in financial inclusion. This program is time-bound, requiring completion within six months, with blockchain-secured certifications globally recognized.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your microfinance goals.",
      "Learn about microcredit, resource allocation, and financial planning.",
      "Explore AI's role in enhancing microfinance systems.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world microfinance challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in microfinance and financial inclusion.",
      "Aligned with the latest global microfinance standards.",
    ],
    benefits: [
      "Gain skills to design and manage microfinance systems.",
      "Access personalized learning tailored to your goals.",
      "Learn to integrate AI into financial inclusion strategies.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Develop problem-solving skills for real-world financial challenges.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for resource allocation and impact measurement.",
      "Suitable for aspiring microfinance professionals and social entrepreneurs.",
      "Access exams online or at physical centers worldwide.",
      "Help create financial opportunities for underserved communities.",
    ],
    features: [
      "Comprehensive study of microfinance and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on financial inclusion and microcredit systems.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of microfinance systems.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in microfinance education.",
      "Aligned with international benchmarks for microfinance training.",
    ],
  },
  "AI Board of Business Administration": {
    heading: "AI Board of Business Administration",
    mainHeading:
      "Redefine Management with the AI Board of Business Administration",
    summary:
      "The AI Board of Business Administration is tailored for individuals aiming to excel in managing businesses effectively. This program integrates business administration principles with Artificial Intelligence (AI) to optimize decision-making and organizational strategies. Starting with a Training Needs Analysis (TNA), the curriculum covers management principles, AI-driven analytics, and operational planning. Exams focus on real-world applications like solving business challenges and improving productivity. This program must be completed within six months, with blockchain-secured certifications globally recognized.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your business management goals.",
      "Learn about management principles, decision-making, and analytics.",
      "Explore AI's role in enhancing business administration.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global recognition.",
      "Focus on solving real-world business challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in business management and consulting.",
      "Aligned with the latest global business administration standards.",
    ],
    benefits: [
      "Gain skills to manage businesses efficiently and strategically.",
      "Access personalized learning tailored to your goals.",
      "Learn to integrate AI into organizational management.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Develop problem-solving skills for real-world business challenges.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for decision-making and operational planning.",
      "Suitable for aspiring managers and business consultants.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in business administration and leadership.",
    ],
    features: [
      "Comprehensive study of business administration and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on business challenges and organizational strategies.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of business management.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in business administration.",
      "Aligned with international benchmarks for management education.",
    ],
  },
  "AI Board of Risk Management": {
    heading: "AI Board of Risk Management",
    mainHeading: "Navigate Uncertainty with the AI Board of Risk Management",
    summary:
      "The AI Board of Risk Management is designed for individuals who want to master the art of identifying and mitigating risks in business and beyond. This program combines risk management strategies with Artificial Intelligence (AI) to improve decision-making and safeguard operations. Starting with a Training Needs Analysis (TNA), the curriculum covers risk assessment, AI-powered predictive tools, and crisis management. Exams focus on real-world applications like analyzing risk scenarios and crafting mitigation plans. This program must be completed within six months, with globally recognized blockchain-secured certifications.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current knowledge and goals.",
      "Learn about risk assessment, predictive tools, and crisis management.",
      "Explore AI's role in managing risks effectively.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world risk management challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in risk management and strategic planning.",
      "Aligned with the latest global standards in risk management.",
    ],
    benefits: [
      "Develop skills to identify and manage risks effectively.",
      "Access personalized learning tailored to your goals.",
      "Learn to integrate AI into risk assessment and mitigation strategies.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Gain expertise in crisis management and predictive analytics.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for real-world risk analysis.",
      "Suitable for aspiring risk managers and strategists.",
      "Access exams online or at physical centers worldwide.",
      "Prepare to safeguard organizations against uncertainties.",
    ],
    features: [
      "Comprehensive study of risk management and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on crisis management and risk assessment.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of risk management.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in risk management tools.",
      "Aligned with international benchmarks for risk management education.",
    ],
  },
  "AI Board of Tourism and Hospitality Management": {
    heading: "AI Board of Tourism and Hospitality Management",
    mainHeading:
      "Redefine Guest Experience with the AI Board of Tourism and Hospitality Management",
    summary:
      "The AI Board of Tourism and Hospitality Management equips candidates with the skills to excel in the tourism and hospitality industry. This program integrates hospitality principles with Artificial Intelligence (AI) to enhance guest experiences and operational efficiency. Starting with a Training Needs Analysis (TNA), the curriculum covers customer service, AI-driven travel planning, and event management. Exams focus on practical applications like crafting hospitality strategies and optimizing operations. This program must be completed within six months, with globally recognized blockchain-secured certifications.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your current knowledge and goals.",
      "Learn about customer service, travel planning, and event management.",
      "Explore AI's role in enhancing guest experiences.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world hospitality challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in tourism and hospitality management.",
      "Aligned with the latest global standards in hospitality education.",
    ],
    benefits: [
      "Gain expertise in the tourism and hospitality sector.",
      "Access personalized learning tailored to your goals.",
      "Learn to integrate AI into guest experience strategies.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Develop problem-solving skills for hospitality challenges.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for travel planning and event coordination.",
      "Suitable for aspiring hospitality managers and travel consultants.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in the global tourism industry.",
    ],
    features: [
      "Comprehensive study of tourism and hospitality management.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on customer service and operational efficiency.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of hospitality operations.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in hospitality education.",
      "Aligned with international benchmarks for tourism management.",
    ],
  },
  "AI Board of Behavioral Economics": {
    heading: "AI Board of Behavioral Economics",
    mainHeading:
      "Understand Decision-Making with the AI Board of Behavioral Economics",
    summary:
      "The AI Board of Behavioral Economics focuses on understanding human decision-making and its impact on markets and businesses. This program integrates behavioral economics principles with Artificial Intelligence (AI) to analyze consumer behavior and economic trends. Starting with a Training Needs Analysis (TNA), the curriculum covers decision-making theories, AI-driven data analysis, and policy design. Exams focus on practical applications like designing strategies based on behavioral insights. This program must be completed within six months, with globally recognized blockchain-secured certifications.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your behavioral economics goals.",
      "Learn about consumer behavior, decision-making, and market trends.",
      "Explore AI's role in analyzing behavioral data.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world behavioral economics challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in market research and economic analysis.",
      "Aligned with the latest global standards in behavioral economics.",
    ],
    benefits: [
      "Gain insights into human behavior and decision-making.",
      "Access personalized learning tailored to your goals.",
      "Learn to integrate AI into behavioral data analysis.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Develop problem-solving skills for real-world economic challenges.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI applications in policy design and market strategies.",
      "Suitable for aspiring economists and market analysts.",
      "Access exams online or at physical centers worldwide.",
      "Prepare to drive impactful change using behavioral insights.",
    ],
    features: [
      "Comprehensive study of behavioral economics and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on decision-making and data analysis.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of consumer behavior.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in behavioral economics.",
      "Aligned with international benchmarks for behavioral education.",
    ],
  },
  "AI Board of Graphic Design": {
    heading: "AI Board of Graphic Design",
    mainHeading: "Design Your Future with the AI Board of Graphic Design",
    summary:
      "The AI Board of Graphic Design is perfect for creative individuals who want to master design principles and tools. This program integrates graphic design with Artificial Intelligence (AI) to enhance creativity and technical skills. Starting with a Training Needs Analysis (TNA), the curriculum covers design fundamentals, AI-driven tools, and branding strategies. Exams focus on practical applications like creating design projects and visual branding campaigns. This program must be completed within six months, with globally recognized blockchain-secured certifications.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your design goals.",
      "Learn about design fundamentals, branding, and AI tools.",
      "Explore AI's role in enhancing creativity and efficiency.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world graphic design challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in graphic design and visual branding.",
      "Aligned with the latest global standards in graphic design education.",
    ],
    benefits: [
      "Develop creative and technical skills in design.",
      "Access personalized learning tailored to your goals.",
      "Learn to integrate AI into design workflows.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Gain expertise in branding and visual storytelling.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for creative projects and branding strategies.",
      "Suitable for aspiring designers and branding professionals.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in design and media industries.",
    ],
    features: [
      "Comprehensive study of graphic design and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on design challenges and branding solutions.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical design knowledge and creativity.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in graphic design.",
      "Aligned with international benchmarks for design education.",
    ],
  },
  "AI Board of Fashion Design": {
    heading: "AI Board of Fashion Design",
    mainHeading:
      "Shape the Future of Fashion with the AI Board of Fashion Design",
    summary:
      "The AI Board of Fashion Design is tailored for individuals passionate about creating innovative fashion trends. This program integrates fashion design with Artificial Intelligence (AI) to revolutionize creativity and production. Starting with a Training Needs Analysis (TNA), the curriculum covers design principles, AI-driven tools, and sustainable fashion strategies. Exams focus on practical applications like crafting fashion collections and sustainable design solutions. This program must be completed within six months, with globally recognized blockchain-secured certifications.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your fashion design goals.",
      "Learn about design principles, sustainability, and AI tools.",
      "Explore AI's role in revolutionizing fashion production.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world fashion design challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in fashion design and trend forecasting.",
      "Aligned with the latest global standards in fashion education.",
    ],
    benefits: [
      "Develop skills in creative and sustainable fashion design.",
      "Access personalized learning tailored to your goals.",
      "Learn to integrate AI into fashion production and design.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Gain expertise in trend forecasting and innovative design.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for sustainable and efficient fashion creation.",
      "Suitable for aspiring fashion designers and trendsetters.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in the global fashion industry.",
    ],
    features: [
      "Comprehensive study of fashion design and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on sustainable fashion and innovation.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of fashion design.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in fashion education.",
      "Aligned with international benchmarks for fashion design training.",
    ],
  },
  "AI Board of Music Composition": {
    heading: "AI Board of Music Composition",
    mainHeading: "Compose the Future with the AI Board of Music Composition",
    summary:
      "The AI Board of Music Composition is perfect for aspiring musicians and composers who want to explore the art of creating music with the help of Artificial Intelligence (AI). This program combines traditional music theory with AI-powered tools to help you craft unique compositions. Starting with a Training Needs Analysis (TNA), the curriculum covers melody creation, AI-generated music techniques, and orchestration. Exams focus on real-world applications like composing original pieces and analyzing music trends. The program must be completed within six months, with globally recognized blockchain-secured certifications.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your music composition goals.",
      "Learn about melody creation, orchestration, and AI tools.",
      "Explore AI's role in revolutionizing music production.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world music composition challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in music composition and production.",
      "Aligned with the latest global standards in music education.",
    ],
    benefits: [
      "Develop skills to compose original and innovative music.",
      "Access personalized learning tailored to your goals.",
      "Learn to integrate AI into music composition workflows.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Gain expertise in melody creation and orchestration.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for creating and analyzing music.",
      "Suitable for aspiring composers and music producers.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in the global music industry.",
    ],
    features: [
      "Comprehensive study of music composition and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on orchestration and melody creation.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of music composition.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in music education.",
      "Aligned with international benchmarks for music composition training.",
    ],
  },
  "AI Board of Film and Media Production": {
    heading: "AI Board of Film and Media Production",
    mainHeading:
      "Create Visual Masterpieces with the AI Board of Film and Media Production",
    summary:
      "The AI Board of Film and Media Production is for creative minds eager to excel in storytelling through films and media. This program integrates traditional filmmaking with Artificial Intelligence (AI) to enhance creativity and technical skills. Starting with a Training Needs Analysis (TNA), the curriculum covers scriptwriting, AI-enhanced editing tools, and production techniques. Exams focus on real-world applications like creating short films and developing media campaigns. This program must be completed within six months, with globally recognized blockchain-secured certifications.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your filmmaking goals.",
      "Learn about scriptwriting, editing, and AI tools.",
      "Explore AI's role in enhancing media production.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world film production challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in film and media production.",
      "Aligned with the latest global standards in media education.",
    ],
    benefits: [
      "Develop storytelling and technical filmmaking skills.",
      "Access personalized learning tailored to your goals.",
      "Learn to integrate AI into film editing and production workflows.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Gain expertise in scriptwriting and media campaign design.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for visual storytelling and editing.",
      "Suitable for aspiring filmmakers and media producers.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in the global media industry.",
    ],
    features: [
      "Comprehensive study of film production and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on storytelling and production techniques.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of media production.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in film education.",
      "Aligned with international benchmarks for film and media training.",
    ],
  },
  "AI Board of Animation and Game Design": {
    heading: "AI Board of Animation and Game Design",
    mainHeading:
      "Shape Digital Worlds with the AI Board of Animation and Game Design",
    summary:
      "The AI Board of Animation and Game Design is perfect for creative individuals passionate about creating immersive digital experiences. This program integrates animation principles with Artificial Intelligence (AI) to enhance game design and visual storytelling. Starting with a Training Needs Analysis (TNA), the curriculum covers 3D modeling, AI-driven character design, and game mechanics. Exams focus on real-world applications like developing animated scenes and crafting interactive games. This program must be completed within six months, with globally recognized blockchain-secured certifications.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your animation and gaming goals.",
      "Learn about 3D modeling, game mechanics, and AI tools.",
      "Explore AI's role in revolutionizing animation and game design.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world animation and gaming challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in animation and game development.",
      "Aligned with the latest global standards in animation education.",
    ],
    benefits: [
      "Develop skills to create immersive digital worlds.",
      "Access personalized learning tailored to your goals.",
      "Learn to integrate AI into animation and gaming workflows.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Gain expertise in 3D modeling and interactive game design.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for character design and game mechanics.",
      "Suitable for aspiring animators and game developers.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in the global animation industry.",
    ],
    features: [
      "Comprehensive study of animation and game design.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on animation challenges and gaming solutions.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of digital design.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in animation technology.",
      "Aligned with international benchmarks for game design education.",
    ],
  },
  "AI Board of Architecture": {
    heading: "AI Board of Architecture",
    mainHeading: "Build Your Vision with the AI Board of Architecture",
    summary:
      "The AI Board of Architecture is designed for individuals interested in designing innovative and sustainable structures. This program integrates architectural principles with Artificial Intelligence (AI) to enhance creativity and planning. Starting with a Training Needs Analysis (TNA), the curriculum covers design fundamentals, AI-driven tools, and sustainable architecture. Exams focus on real-world applications like drafting building plans and solving design challenges. This program must be completed within six months, with globally recognized blockchain-secured certifications.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your architectural goals.",
      "Learn about sustainable design, planning, and AI tools.",
      "Explore AI's role in enhancing architectural creativity.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world architectural challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in architecture and urban planning.",
      "Aligned with the latest global standards in architecture education.",
    ],
    benefits: [
      "Develop skills to design innovative and sustainable structures.",
      "Access personalized learning tailored to your goals.",
      "Learn to integrate AI into architectural planning and design.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Gain expertise in sustainable architecture and planning.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for design and construction.",
      "Suitable for aspiring architects and urban planners.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in the global architectural industry.",
    ],
    features: [
      "Comprehensive study of architecture and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on design challenges and sustainability.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of architecture.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in architecture education.",
      "Aligned with international benchmarks for architectural training.",
    ],
  },
  "AI Board of Interior Design": {
    heading: "AI Board of Interior Design",
    mainHeading: "Transform Spaces with the AI Board of Interior Design",
    summary:
      "The AI Board of Interior Design is tailored for individuals passionate about creating functional and aesthetic spaces. This program integrates interior design principles with Artificial Intelligence (AI) to enhance creativity and technical skills. Starting with a Training Needs Analysis (TNA), the curriculum covers space planning, AI-driven design tools, and sustainable interiors. Exams focus on practical applications like crafting design plans and solving space challenges. This program must be completed within six months, with globally recognized blockchain-secured certifications.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your interior design goals.",
      "Learn about space planning, sustainable interiors, and AI tools.",
      "Explore AI's role in revolutionizing interior design.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world interior design challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in interior design and space planning.",
      "Aligned with the latest global standards in interior design education.",
    ],
    benefits: [
      "Develop skills to create functional and aesthetic spaces.",
      "Access personalized learning tailored to your goals.",
      "Learn to integrate AI into interior design workflows.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Gain expertise in sustainable and innovative design.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for space planning and creativity.",
      "Suitable for aspiring interior designers and decorators.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in the global interior design industry.",
    ],
    features: [
      "Comprehensive study of interior design and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on space planning and sustainability.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of interior design.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in interior design.",
      "Aligned with international benchmarks for interior design training.",
    ],
  },
  "AI Board of Fine Arts": {
    heading: "AI Board of Fine Arts",
    mainHeading: "Explore Creativity with the AI Board of Fine Arts",
    summary:
      "The AI Board of Fine Arts is for anyone passionate about painting, sculpture, and visual arts. This program blends traditional fine arts techniques with Artificial Intelligence (AI) to unleash creativity and expand artistic horizons. Starting with a Training Needs Analysis (TNA), the curriculum covers art fundamentals, AI-driven design tools, and modern art trends. Exams focus on creating original art pieces and analyzing contemporary styles. Complete the program in six months to receive a blockchain-secured certification.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your artistic goals.",
      "Learn about painting, sculpture, and modern art trends.",
      "Explore AI tools for enhancing creativity.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on creating original art pieces and analyzing art styles.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in fine arts and creative industries.",
      "Aligned with the latest global standards in art education.",
    ],
    benefits: [
      "Unleash your creativity with fine arts techniques.",
      "Access personalized learning tailored to your artistic skills.",
      "Learn to integrate AI into art creation.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Develop expertise in painting, sculpture, and design.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for modern and experimental art.",
      "Suitable for aspiring artists and designers.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in the creative arts industry.",
    ],
    features: [
      "Comprehensive study of fine arts and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on modern art and AI tools.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of fine arts.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in art education.",
      "Aligned with international benchmarks for fine arts training.",
    ],
  },
  "AI Board of Creative Writing": {
    heading: "AI Board of Creative Writing",
    mainHeading: "Master Storytelling with the AI Board of Creative Writing",
    summary:
      "The AI Board of Creative Writing is ideal for aspiring writers looking to hone their storytelling skills. This program integrates traditional writing techniques with Artificial Intelligence (AI) to enhance creativity and productivity. Starting with a Training Needs Analysis (TNA), the curriculum covers story structure, AI-powered writing tools, and character development. Exams focus on crafting compelling narratives and editing skills. Complete the program in six months to earn a blockchain-secured certification.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your writing goals.",
      "Learn about storytelling, character development, and editing.",
      "Explore AI tools to enhance writing creativity.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on crafting compelling stories and editing techniques.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in writing and publishing.",
      "Aligned with the latest global standards in creative writing education.",
    ],
    benefits: [
      "Improve your storytelling and writing skills.",
      "Access personalized learning tailored to your writing style.",
      "Learn to integrate AI into writing workflows.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Develop expertise in crafting narratives and character building.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for editing and enhancing writing productivity.",
      "Suitable for aspiring authors, poets, and screenwriters.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in the writing and publishing industry.",
    ],
    features: [
      "Comprehensive study of creative writing and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on storytelling and writing tools.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of creative writing.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in creative writing.",
      "Aligned with international benchmarks for writing education.",
    ],
  },
  "AI Board of Photography": {
    heading: "AI Board of Photography",
    mainHeading: "Capture Moments with the AI Board of Photography",
    summary:
      "The AI Board of Photography is for aspiring photographers eager to master the art of capturing compelling images. This program integrates photography techniques with Artificial Intelligence (AI) to enhance editing and creative expression. Starting with a Training Needs Analysis (TNA), the curriculum covers camera techniques, AI-powered editing tools, and visual storytelling. Exams focus on creating portfolios and analyzing photographic styles. Complete the program in six months to earn a blockchain-secured certification.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your photography goals.",
      "Learn about camera techniques, editing, and visual storytelling.",
      "Explore AI tools for enhancing photography.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on creating impactful portfolios and analyzing styles.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in photography and visual arts.",
      "Aligned with the latest global standards in photography education.",
    ],
    benefits: [
      "Develop skills to capture and edit compelling images.",
      "Access personalized learning tailored to your artistic vision.",
      "Learn to integrate AI into photography workflows.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Gain expertise in camera techniques and visual storytelling.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for enhancing and editing photographs.",
      "Suitable for aspiring photographers and visual artists.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in photography and media industries.",
    ],
    features: [
      "Comprehensive study of photography and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on visual storytelling and AI tools.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of photography.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in photography education.",
      "Aligned with international benchmarks for visual arts training.",
    ],
  },
  "AI Board of Performing Arts": {
    heading: "AI Board of Performing Arts",
    mainHeading: "Elevate Your Talent with the AI Board of Performing Arts",
    summary:
      "The AI Board of Performing Arts is for individuals passionate about dance, theater, and live performances. This program integrates traditional performing arts techniques with Artificial Intelligence (AI) to enhance creativity and stagecraft. Starting with a Training Needs Analysis (TNA), the curriculum covers acting, choreography, and AI-driven stage design. Exams focus on real-world applications like creating performances and managing stage productions. Complete the program in six months to receive a blockchain-secured certification.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your performing arts goals.",
      "Learn about acting, choreography, and stage management.",
      "Explore AI tools for enhancing live performances.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on creating impactful performances and stagecraft.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in theater, dance, and performing arts.",
      "Aligned with the latest global standards in performing arts education.",
    ],
    benefits: [
      "Hone your skills in acting, dancing, and live performance.",
      "Access personalized learning tailored to your artistic goals.",
      "Learn to integrate AI into performing arts workflows.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Gain expertise in stage design and choreography.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for managing live performances.",
      "Suitable for aspiring performers and stage managers.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in the performing arts industry.",
    ],
    features: [
      "Comprehensive study of performing arts and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on acting, dancing, and stagecraft.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of performing arts.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in performing arts education.",
      "Aligned with international benchmarks for artistic training.",
    ],
  },
  "AI Board of Literary Arts": {
    heading: "AI Board of Literary Arts",
    mainHeading: "Discover Words with the AI Board of Literary Arts",
    summary:
      "The AI Board of Literary Arts is perfect for aspiring poets, novelists, and literary enthusiasts. This program combines literary theory with Artificial Intelligence (AI) to enhance creative expression and analytical skills. Starting with a Training Needs Analysis (TNA), the curriculum covers poetry, fiction writing, and AI-driven literary analysis. Exams focus on crafting literary works and analyzing texts. Complete the program in six months to receive a blockchain-secured certification.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your literary goals.",
      "Learn about poetry, fiction writing, and literary analysis.",
      "Explore AI tools for enhancing literary creativity.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on crafting original literary works and analyzing texts.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in writing, publishing, and literary analysis.",
      "Aligned with the latest global standards in literary arts education.",
    ],
    benefits: [
      "Develop skills to write and analyze compelling literary works.",
      "Access personalized learning tailored to your creative vision.",
      "Learn to integrate AI into literary analysis workflows.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Gain expertise in poetry, fiction, and literary criticism.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for enhancing literary analysis and creativity.",
      "Suitable for aspiring authors and literary analysts.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in the literary and publishing industries.",
    ],
    features: [
      "Comprehensive study of literary arts and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on poetry, fiction, and literary analysis.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of literary arts.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in literary education.",
      "Aligned with international benchmarks for literary arts training.",
    ],
  },
  "AI Board of Industrial Design": {
    heading: "AI Board of Industrial Design",
    mainHeading: "Innovate with the AI Board of Industrial Design",
    summary:
      "The AI Board of Industrial Design is for creators who dream of designing functional and aesthetically pleasing products. This program integrates traditional design principles with Artificial Intelligence (AI) to enhance creativity and technical skills. Starting with a Training Needs Analysis (TNA), the curriculum covers product design, AI-driven tools, and sustainable manufacturing techniques. Exams focus on creating innovative designs and solving real-world challenges. Complete the program in six months to earn a blockchain-secured certification.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your industrial design goals.",
      "Learn about product design, sustainability, and AI tools.",
      "Explore AI's role in enhancing industrial creativity.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world product design challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in industrial design and manufacturing.",
      "Aligned with the latest global standards in industrial design education.",
    ],
    benefits: [
      "Learn to create innovative and functional product designs.",
      "Access personalized learning tailored to your skills.",
      "Integrate AI into design and manufacturing workflows.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Develop expertise in sustainable design and manufacturing.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for industrial design and prototyping.",
      "Suitable for aspiring industrial designers and innovators.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in the industrial design sector.",
    ],
    features: [
      "Comprehensive study of industrial design and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on sustainable design and prototyping.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of industrial design.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in design technology.",
      "Aligned with international benchmarks for industrial design training.",
    ],
  },
  "AI Board of Visual Effects (VFX) and Animation": {
    heading: "AI Board of Visual Effects (VFX) and Animation",
    mainHeading:
      "Create Stunning Visuals with the AI Board of VFX and Animation",
    summary:
      "The AI Board of Visual Effects (VFX) and Animation is for individuals who want to bring their imaginations to life through visual storytelling. This program combines animation techniques with Artificial Intelligence (AI) to enhance creativity and technical expertise. Starting with a Training Needs Analysis (TNA), the curriculum covers animation fundamentals, AI-driven VFX tools, and 3D rendering. Exams focus on creating animation projects and developing visual effects for real-world applications. Complete the program in six months to earn a blockchain-secured certification.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your animation goals.",
      "Learn about VFX tools, 3D rendering, and AI techniques.",
      "Explore AI's role in revolutionizing visual storytelling.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on creating animations and visual effects for real-world use.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in animation and visual effects.",
      "Aligned with the latest global standards in animation education.",
    ],
    benefits: [
      "Master animation and visual effects techniques.",
      "Access personalized learning tailored to your artistic skills.",
      "Learn to integrate AI into animation workflows.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Develop expertise in 3D rendering and VFX production.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for enhancing animation and effects.",
      "Suitable for aspiring animators and VFX artists.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in animation and film industries.",
    ],
    features: [
      "Comprehensive study of VFX and animation with AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on visual storytelling and 3D rendering.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of animation and VFX.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in animation tools.",
      "Aligned with international benchmarks for VFX and animation training.",
    ],
  },
  "AI Board of Jewelry Design": {
    heading: "AI Board of Jewelry Design",
    mainHeading: "Craft Masterpieces with the AI Board of Jewelry Design",
    summary:
      "The AI Board of Jewelry Design is for creative minds who dream of designing unique and elegant jewelry. This program combines traditional design techniques with Artificial Intelligence (AI) to revolutionize craftsmanship and innovation. Starting with a Training Needs Analysis (TNA), the curriculum covers jewelry design principles, AI-driven modeling tools, and sustainability in production. Exams focus on creating jewelry collections and solving design challenges. Complete the program in six months to earn a blockchain-secured certification.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your jewelry design goals.",
      "Learn about design techniques, modeling, and AI tools.",
      "Explore AI's role in enhancing jewelry craftsmanship.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on creating unique and elegant jewelry pieces.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in jewelry design and manufacturing.",
      "Aligned with the latest global standards in jewelry education.",
    ],
    benefits: [
      "Master techniques for creating elegant jewelry designs.",
      "Access personalized learning tailored to your design skills.",
      "Learn to integrate AI into jewelry design workflows.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Develop expertise in sustainable and innovative design.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for crafting and modeling jewelry.",
      "Suitable for aspiring jewelry designers and artisans.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in the jewelry industry.",
    ],
    features: [
      "Comprehensive study of jewelry design and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on sustainable and creative design.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of jewelry design.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in jewelry tools.",
      "Aligned with international benchmarks for jewelry design training.",
    ],
  },
  "AI Board of Coding and Development": {
    heading: "AI Board of Coding and Development",
    mainHeading: "Code the Future with the AI Board of Coding and Development",
    summary:
      "The AI Board of Coding and Development is perfect for aspiring coders who want to master programming languages and software development. This program integrates coding fundamentals with Artificial Intelligence (AI) to enhance problem-solving and productivity. Starting with a Training Needs Analysis (TNA), the curriculum covers programming languages, AI-driven development tools, and software lifecycle management. Exams focus on coding projects and solving development challenges. Complete the program in six months to earn a blockchain-secured certification.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your coding goals.",
      "Learn programming languages, software development, and AI tools.",
      "Explore AI's role in enhancing coding workflows.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on coding projects and real-world development challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in software development and engineering.",
      "Aligned with the latest global standards in coding education.",
    ],
    benefits: [
      "Master programming languages and development frameworks.",
      "Access personalized learning tailored to your coding skills.",
      "Learn to integrate AI into coding and development workflows.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Develop expertise in solving real-world software challenges.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for coding productivity and efficiency.",
      "Suitable for aspiring developers and software engineers.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in the tech industry.",
    ],
    features: [
      "Comprehensive study of coding and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on programming and software management.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of coding.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in coding tools.",
      "Aligned with international benchmarks for coding education.",
    ],
  },
  "AI Board of Web Development": {
    heading: "AI Board of Web Development",
    mainHeading: "Build the Web with the AI Board of Web Development",
    summary:
      "The AI Board of Web Development is ideal for individuals interested in creating websites and web applications. This program integrates web development techniques with Artificial Intelligence (AI) to enhance design, functionality, and user experience. Starting with a Training Needs Analysis (TNA), the curriculum covers frontend and backend development, AI-driven tools, and responsive design principles. Exams focus on building web projects and solving real-world web challenges. Complete the program in six months to earn a blockchain-secured certification.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your web development goals.",
      "Learn frontend and backend development with AI tools.",
      "Explore AI's role in enhancing web functionality.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on creating responsive and user-friendly web designs.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in web development and UI/UX design.",
      "Aligned with the latest global standards in web development education.",
    ],
    benefits: [
      "Master skills in frontend and backend web development.",
      "Access personalized learning tailored to your technical expertise.",
      "Learn to integrate AI into web design and functionality.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Develop expertise in creating user-friendly and responsive websites.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for web performance and optimization.",
      "Suitable for aspiring web developers and designers.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in the web and tech industries.",
    ],
    features: [
      "Comprehensive study of web development and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on responsive design and optimization.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of web development.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in web technology.",
      "Aligned with international benchmarks for web development training.",
    ],
  },
  "AI Board of App Development": {
    heading: "AI Board of App Development",
    mainHeading: "Create Innovative Apps with the AI Board of App Development",
    summary:
      "The AI Board of App Development is perfect for anyone eager to design and develop cutting-edge mobile and web applications. This program combines app development fundamentals with Artificial Intelligence (AI) to enhance user experiences and streamline coding. Starting with a Training Needs Analysis (TNA), the curriculum covers programming languages, AI-powered app design, and optimization techniques. Exams focus on building functional and user-friendly apps. Complete the program in six months to earn a globally recognized blockchain-secured certification.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your app development goals.",
      "Learn programming languages, app design, and AI tools.",
      "Explore AI's role in enhancing app functionality and user experience.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on creating innovative and user-friendly applications.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in app development and software engineering.",
      "Aligned with the latest global standards in app development education.",
    ],
    benefits: [
      "Develop skills in mobile and web app development.",
      "Access personalized learning tailored to your technical needs.",
      "Learn to integrate AI into app design and coding.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Gain expertise in user experience and app optimization.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for app performance and analytics.",
      "Suitable for aspiring app developers and software engineers.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in the tech industry.",
    ],
    features: [
      "Comprehensive study of app development and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on AI-driven app design and coding.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of app development.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in app development tools.",
      "Aligned with international benchmarks for app development training.",
    ],
  },
  "AI Board of Automotive Engineering": {
    heading: "AI Board of Automotive Engineering",
    mainHeading: "Drive Innovation with the AI Board of Automotive Engineering",
    summary:
      "The AI Board of Automotive Engineering is for those passionate about designing and innovating vehicles. This program integrates engineering principles with Artificial Intelligence (AI) to develop smarter, more efficient automotive solutions. Starting with a Training Needs Analysis (TNA), the curriculum covers vehicle mechanics, AI-driven design tools, and sustainable automotive technologies. Exams focus on creating innovative designs and solving engineering challenges. Complete the program in six months to earn a blockchain-secured certification.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your automotive engineering goals.",
      "Learn vehicle mechanics, design, and AI tools.",
      "Explore AI's role in revolutionizing the automotive industry.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world automotive engineering challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in automotive engineering and design.",
      "Aligned with the latest global standards in automotive education.",
    ],
    benefits: [
      "Master vehicle design and engineering techniques.",
      "Access personalized learning tailored to your skills.",
      "Learn to integrate AI into automotive workflows.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Develop expertise in sustainable and smart vehicle technologies.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for design and engineering innovation.",
      "Suitable for aspiring automotive engineers and designers.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in the automotive sector.",
    ],
    features: [
      "Comprehensive study of automotive engineering and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on smart vehicle technologies and design.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of automotive engineering.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in automotive technology.",
      "Aligned with international benchmarks for automotive engineering training.",
    ],
  },
  "AI Board of Food Science and Technology": {
    heading: "AI Board of Food Science and Technology",
    mainHeading: "Innovate with the AI Board of Food Science and Technology",
    summary:
      "The AI Board of Food Science and Technology is for individuals eager to revolutionize the food industry. This program integrates food science principles with Artificial Intelligence (AI) to enhance research, production, and sustainability. Starting with a Training Needs Analysis (TNA), the curriculum covers food safety, AI-driven analysis tools, and sustainable food technology. Exams focus on solving real-world food science challenges. Complete the program in six months to earn a blockchain-secured certification.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your food science goals.",
      "Learn about food safety, production, and AI tools.",
      "Explore AI's role in advancing food technology.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world food science challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in food science and technology.",
      "Aligned with the latest global standards in food education.",
    ],
    benefits: [
      "Develop skills in food safety and sustainable production.",
      "Access personalized learning tailored to your expertise.",
      "Learn to integrate AI into food research and production workflows.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Gain expertise in solving challenges in food science.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for food analysis and sustainability.",
      "Suitable for aspiring food scientists and technologists.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in the food industry.",
    ],
    features: [
      "Comprehensive study of food science and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on sustainable food technologies.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of food science.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in food technology.",
      "Aligned with international benchmarks for food science training.",
    ],
  },
  "AI Board of Blockchain Development": {
    heading: "AI Board of Blockchain Development",
    mainHeading:
      "Master Blockchain with the AI Board of Blockchain Development",
    summary:
      "The AI Board of Blockchain Development is perfect for individuals passionate about decentralized technology. This program combines blockchain fundamentals with Artificial Intelligence (AI) to enhance efficiency and innovation in the field. Starting with a Training Needs Analysis (TNA), the curriculum covers blockchain architecture, AI-powered security tools, and smart contract development. Exams focus on real-world blockchain projects. Complete the program in six months to earn a blockchain-secured certification.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your blockchain development goals.",
      "Learn about blockchain architecture, smart contracts, and AI tools.",
      "Explore AI's role in advancing blockchain technologies.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world blockchain challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in blockchain development and security.",
      "Aligned with the latest global standards in blockchain education.",
    ],
    benefits: [
      "Master blockchain technologies and smart contract development.",
      "Access personalized learning tailored to your expertise.",
      "Learn to integrate AI into blockchain workflows.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Gain expertise in decentralized technologies and security.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for enhancing blockchain performance.",
      "Suitable for aspiring blockchain developers and architects.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in the blockchain industry.",
    ],
    features: [
      "Comprehensive study of blockchain and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on blockchain architecture and smart contracts.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of blockchain.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in blockchain technology.",
      "Aligned with international benchmarks for blockchain training.",
    ],
  },
  "AI Agricultural Board of Examination": {
    heading: "AI Agricultural Board of Examination",
    mainHeading:
      "Revolutionize Farming with the AI Agricultural Board of Examination",
    summary:
      "The AI Agricultural Board of Examination is designed for those interested in modernizing agriculture. This program integrates farming principles with Artificial Intelligence (AI) to improve productivity, sustainability, and innovation. Starting with a Training Needs Analysis (TNA), the curriculum covers crop management, AI-powered tools, and sustainable farming practices. Exams focus on solving real-world agricultural challenges. Complete the program in six months to earn a blockchain-secured certification.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your agricultural goals.",
      "Learn about crop management, sustainable farming, and AI tools.",
      "Explore AI's role in advancing agricultural productivity.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world agricultural challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in modern farming and agribusiness.",
      "Aligned with the latest global standards in agricultural education.",
    ],
    benefits: [
      "Develop skills in sustainable and innovative farming practices.",
      "Access personalized learning tailored to your agricultural expertise.",
      "Learn to integrate AI into crop management workflows.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Gain expertise in solving agricultural productivity challenges.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for precision farming and analytics.",
      "Suitable for aspiring agronomists and agricultural scientists.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in the agriculture industry.",
    ],
    features: [
      "Comprehensive study of agriculture and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on sustainable farming and precision agriculture.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of agriculture.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in agricultural technology.",
      "Aligned with international benchmarks for agricultural training.",
    ],
  },
  "AI Board of Renewable Technology": {
    heading: "AI Board of Renewable Technology",
    mainHeading:
      "Innovate Sustainably with the AI Board of Renewable Technology",
    summary:
      "The AI Board of Renewable Technology is for those passionate about creating a greener future. This program integrates renewable energy concepts with Artificial Intelligence (AI) to develop efficient and innovative energy solutions. Starting with a Training Needs Analysis (TNA), the curriculum covers solar power, wind energy, AI-powered energy systems, and sustainability strategies. Exams focus on solving real-world renewable energy challenges. Complete the program in six months to earn a blockchain-secured certification.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your renewable energy goals.",
      "Learn about solar power, wind energy, and AI-driven energy systems.",
      "Explore AI's role in advancing renewable energy technologies.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world energy challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in renewable energy and sustainability.",
      "Aligned with the latest global standards in renewable technology education.",
    ],
    benefits: [
      "Master renewable energy technologies like solar and wind power.",
      "Access personalized learning tailored to your energy goals.",
      "Learn to integrate AI into renewable energy systems.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Develop expertise in sustainable energy production.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for optimizing energy systems.",
      "Suitable for aspiring renewable energy professionals.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in green technology innovation.",
    ],
    features: [
      "Comprehensive study of renewable energy and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on solar, wind, and sustainable technologies.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of renewable energy.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in renewable technologies.",
      "Aligned with international benchmarks for renewable energy training.",
    ],
  },
  "AI Board of Green Tech Innovation": {
    heading: "AI Board of Green Tech Innovation",
    mainHeading: "Pioneer Change with the AI Board of Green Tech Innovation",
    summary:
      "The AI Board of Green Tech Innovation is designed for individuals who want to create and implement sustainable technologies. This program combines green tech principles with Artificial Intelligence (AI) to foster eco-friendly innovation. Starting with a Training Needs Analysis (TNA), the curriculum covers eco-friendly design, AI-driven green tech tools, and climate change solutions. Exams focus on solving real-world green tech challenges. Complete the program in six months to earn a blockchain-secured certification.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your green tech goals.",
      "Learn about eco-friendly technologies and AI tools.",
      "Explore AI's role in fostering sustainable innovation.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world green tech challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in green tech and environmental sustainability.",
      "Aligned with the latest global standards in green tech education.",
    ],
    benefits: [
      "Master eco-friendly design and sustainable technologies.",
      "Access personalized learning tailored to your green tech goals.",
      "Learn to integrate AI into green tech workflows.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Gain expertise in solving climate change challenges.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for sustainable innovation.",
      "Suitable for aspiring green tech innovators and developers.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in environmental sustainability.",
    ],
    features: [
      "Comprehensive study of green technology and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on sustainable innovation and eco-friendly design.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of green tech.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in green technology.",
      "Aligned with international benchmarks for green tech training.",
    ],
  },
  "AI Board of Energy Systems": {
    heading: "AI Board of Energy Systems",
    mainHeading: "Revolutionize Power with the AI Board of Energy Systems",
    summary:
      "The AI Board of Energy Systems is for those who aim to transform how energy is produced and managed. This program integrates energy systems engineering with Artificial Intelligence (AI) to enhance efficiency and innovation. Starting with a Training Needs Analysis (TNA), the curriculum covers energy production, grid management, AI-powered systems, and sustainable practices. Exams focus on solving real-world energy system challenges. Complete the program in six months to earn a blockchain-secured certification.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your energy system goals.",
      "Learn about energy grids, production, and AI tools.",
      "Explore AI's role in modernizing energy systems.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world energy challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in energy systems engineering.",
      "Aligned with the latest global standards in energy education.",
    ],
    benefits: [
      "Master energy grid management and AI-driven systems.",
      "Access personalized learning tailored to your energy goals.",
      "Learn to integrate AI into energy production workflows.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Develop expertise in sustainable energy systems.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for enhancing energy efficiency.",
      "Suitable for aspiring energy engineers and system designers.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in energy system innovation.",
    ],
    features: [
      "Comprehensive study of energy systems and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on grid management and energy efficiency.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of energy systems.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in energy technologies.",
      "Aligned with international benchmarks for energy training.",
    ],
  },
  "AI Board of Political Science": {
    heading: "AI Board of Political Science",
    mainHeading: "Shape the Future with the AI Board of Political Science",
    summary:
      "The AI Board of Political Science is for those passionate about understanding and influencing governance, policies, and global politics. This program integrates political theory with Artificial Intelligence (AI) to analyze trends, policies, and governance strategies. Starting with a Training Needs Analysis (TNA), the curriculum covers international relations, AI-powered policy analysis, and governance structures. Exams focus on solving real-world political challenges. Complete the program in six months to earn a blockchain-secured certification.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your political science goals.",
      "Learn about governance, international relations, and AI tools.",
      "Explore AI's role in political analysis and strategy.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world political and governance challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in policy-making and governance.",
      "Aligned with the latest global standards in political science education.",
    ],
    benefits: [
      "Master policy analysis and governance strategies.",
      "Access personalized learning tailored to your political goals.",
      "Learn to integrate AI into political analysis workflows.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Develop expertise in international relations and policy-making.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for analyzing political trends.",
      "Suitable for aspiring political analysts and policy-makers.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in governance and diplomacy.",
    ],
    features: [
      "Comprehensive study of political science and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on governance and international relations.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of political science.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in political science.",
      "Aligned with international benchmarks for political science training.",
    ],
  },
  "AI Board of Sociology": {
    heading: "AI Board of Sociology",
    mainHeading: "Understand Society with the AI Board of Sociology",
    summary:
      "The AI Board of Sociology is for individuals who want to explore and analyze human behavior and societal trends. This program integrates sociological theories with Artificial Intelligence (AI) to study communities, cultures, and social systems. Starting with a Training Needs Analysis (TNA), the curriculum covers social research, AI-driven analysis tools, and global sociology trends. Exams focus on solving real-world sociological challenges. Complete the program in six months to earn a blockchain-secured certification.",
    highlights: [
      "Open to everyone, regardless of age or educational background.",
      "Personalized programs tailored to your sociological goals.",
      "Learn about cultural studies, social systems, and AI tools.",
      "Explore AI's role in analyzing societal trends.",
      "Exams must be completed within six months for timely progression.",
      "Blockchain-secured certifications ensure global trust and recognition.",
      "Focus on solving real-world societal challenges.",
      "Flexible exam options: online or at global centers.",
      "Prepares you for careers in sociology and social research.",
      "Aligned with the latest global standards in sociology education.",
    ],
    benefits: [
      "Master social research and societal analysis techniques.",
      "Access personalized learning tailored to your sociological goals.",
      "Learn to integrate AI into social research workflows.",
      "Earn globally recognized and blockchain-secured certifications.",
      "Gain expertise in analyzing cultural and social systems.",
      "Complete the program within a focused six-month timeline.",
      "Explore AI tools for studying societal trends.",
      "Suitable for aspiring sociologists and social researchers.",
      "Access exams online or at physical centers worldwide.",
      "Prepare for impactful roles in social research and analysis.",
    ],
    features: [
      "Comprehensive study of sociology and AI integration.",
      "Training Needs Analysis to customize your learning experience.",
      "Advanced modules on social systems and cultural studies.",
      "Time-bound program requiring exam completion within six months.",
      "Blockchain-secured certification for global recognition.",
      "Exams designed to test practical knowledge of sociology.",
      "Flexible options for online or in-person testing.",
      "High standards requiring top 90% scores for certification.",
      "Programs updated to reflect advancements in sociological research.",
      "Aligned with international benchmarks for sociology training.",
    ],
  },
  "AI Board of Anthropology": {
    heading: "AI Board of Anthropology",
    mainHeading: "Advancing Anthropology with Artificial Intelligence",
    summary: `
          The AI Board of Anthropology is dedicated to advancing the study of human cultures, societies, 
          and behaviors using cutting-edge artificial intelligence. This board combines traditional 
          anthropology with AI tools to uncover patterns, trends, and insights about human history and 
          present-day communities. By leveraging AI-powered data analysis and machine learning, the 
          board supports research, education, and innovation in anthropology. It bridges the gap 
          between technology and human understanding, helping scholars, researchers, and students 
          explore the rich diversity of humanity efficiently and accurately.
        `,
    highlights: [
      "Combines traditional anthropology with advanced AI tools",
      "Offers global research programs and collaborative initiatives",
      "Provides cutting-edge AI training for anthropology professionals",
      "Supports data-driven insights into human cultures and societies",
      "Encourages networking with experts and peers worldwide",
      "Promotes innovative solutions to global challenges using AI in anthropology",
      "Focuses on real-world applications of AI in cultural studies",
      "Hosts interactive workshops and learning sessions",
      "Integrates machine learning and big data analytics into anthropology research",
      "Facilitates the development of new theories and methodologies",
    ],
    benefits: [
      "Gain expertise in using AI to analyze human behavior and culture",
      "Access a global network of professionals in anthropology and AI",
      "Enhance your research capabilities with advanced tools and techniques",
      "Develop innovative solutions to real-world problems in anthropology",
      "Improve your career prospects with cutting-edge AI knowledge",
      "Participate in collaborative research projects with leading experts",
      "Learn from workshops and training sessions designed for all skill levels",
      "Stay ahead in the field with insights into the latest AI advancements",
      "Build impactful connections in both academia and industry",
      "Contribute to shaping the future of anthropology and technology",
    ],
    features: [
      "AI-Powered Research Tools: Access advanced tools for analyzing cultural and societal data",
      "Global Collaboration: Participate in international projects and research initiatives",
      "Expert-Led Training: Learn from top professionals in anthropology and AI",
      "Interactive Workshops: Hands-on sessions to enhance practical knowledge",
      "Real-World Applications: Focus on solving contemporary issues with AI in anthropology",
      "Networking Opportunities: Connect with a global community of scholars and experts",
      "Cutting-Edge Curriculum: Stay updated with the latest trends and methodologies",
      "AI Integration: Learn how to apply AI techniques to traditional anthropology",
      "Custom Learning Paths: Tailored programs to meet individual goals and interests",
      "Recognition and Certification: Earn globally recognized credentials to advance your career",
    ],
  },
  "AI Board of History": {
    heading: "AI Board of History",
    mainHeading:
      "Revolutionizing Historical Studies with Artificial Intelligence",
    summary: `
        The AI Board of History uses advanced artificial intelligence to analyze historical events, patterns, and trends. 
        It combines traditional methods with AI-powered tools to uncover new insights about the past and how it shapes 
        the present and future. By integrating machine learning, data analysis, and historical expertise, this board 
        helps students, researchers, and historians deepen their understanding of history in innovative ways.
      `,
    highlights: [
      "Combines AI with traditional historical research",
      "Offers advanced tools for analyzing historical data",
      "Promotes innovative approaches to studying the past",
      "Supports global collaboration among historians",
      "Hosts workshops on AI in history",
      "Provides training in machine learning for historians",
      "Uncovers trends and patterns in historical events",
      "Focuses on real-world applications of history and AI",
      "Enables efficient historical research using big data",
      "Encourages the development of new historical theories",
    ],
    benefits: [
      "Learn to use AI tools for historical research",
      "Gain insights into historical patterns and trends",
      "Access resources for data-driven history studies",
      "Connect with a global network of historians",
      "Improve research efficiency with AI-powered tools",
      "Explore innovative methods in historical studies",
      "Develop skills in data analysis for historical research",
      "Understand how history shapes modern society",
      "Participate in workshops led by industry experts",
      "Earn certifications in AI-integrated historical studies",
    ],
    features: [
      "AI-Powered Analysis: Explore history with cutting-edge tools",
      "Data-Driven Insights: Identify patterns in historical data",
      "Expert-Led Training: Learn from leading historians and AI experts",
      "Interactive Workshops: Gain practical experience in AI for history",
      "Global Collaboration: Work with historians worldwide",
      "Real-World Applications: Use AI to address historical challenges",
      "Custom Learning Paths: Tailored programs for diverse interests",
      "Cutting-Edge Curriculum: Stay updated on new methodologies",
      "Networking Opportunities: Connect with a global community",
      "Recognition and Certification: Advance your career with credentials",
    ],
  },
  "AI Board of Geography": {
    heading: "AI Board of Geography",
    mainHeading: "Transforming Geographic Studies with Artificial Intelligence",
    summary: `
        The AI Board of Geography combines traditional geographic studies with advanced AI tools to explore the Earth’s 
        landscapes, environments, and spatial patterns. It uses AI-driven data analytics, satellite imagery, and machine 
        learning to solve real-world challenges like climate change and urban planning, helping students and professionals 
        understand the world in new, impactful ways.
      `,
    highlights: [
      "Uses AI to enhance geographic research",
      "Analyzes spatial data with advanced tools",
      "Focuses on real-world applications like climate change",
      "Offers training in AI and geographic information systems (GIS)",
      "Supports global collaboration among geographers",
      "Provides workshops on AI-driven geographic research",
      "Enables efficient environmental and urban studies",
      "Integrates big data analytics into geographic research",
      "Promotes innovative approaches to studying the Earth",
      "Encourages sustainable solutions through AI",
    ],
    benefits: [
      "Learn to analyze spatial data using AI tools",
      "Gain insights into environmental and urban patterns",
      "Improve research with AI-powered geographic tools",
      "Connect with a global network of geographers",
      "Explore solutions to real-world geographic challenges",
      "Enhance career opportunities in geography and AI",
      "Participate in workshops led by geography experts",
      "Understand the impact of AI on geographic studies",
      "Develop skills in GIS and data analytics",
      "Earn certifications in AI-integrated geographic studies",
    ],
    features: [
      "AI-Driven Geographic Tools: Explore spatial data efficiently",
      "Satellite Imagery Analysis: Gain advanced insights",
      "Expert-Led Training: Learn from geography and AI specialists",
      "Workshops and Seminars: Practical learning opportunities",
      "Global Collaboration: Network with geographers worldwide",
      "Real-World Applications: Solve challenges like climate change",
      "Custom Learning Paths: Tailored to your interests",
      "Cutting-Edge Curriculum: Stay updated with innovations",
      "Networking Opportunities: Build connections in the field",
      "Certification Programs: Advance your career with recognition",
    ],
  },
  "AI Board of Philosophy": {
    heading: "AI Board of Philosophy",
    mainHeading: "Innovating Philosophy with Artificial Intelligence",
    summary: `
        The AI Board of Philosophy explores how artificial intelligence can enhance our understanding of ethical, 
        existential, and philosophical questions. It bridges the gap between technology and humanity, offering 
        tools and insights to analyze philosophical concepts, address ethical challenges in AI, and rethink traditional 
        philosophies in the modern era.
      `,
    highlights: [
      "Integrates AI with philosophical studies",
      "Focuses on ethical challenges in AI",
      "Explores existential questions through technology",
      "Provides training in AI and philosophy",
      "Hosts workshops on AI ethics and philosophy",
      "Promotes global discussions on philosophy and AI",
      "Encourages innovative approaches to philosophical research",
      "Analyzes the impact of AI on human values",
      "Supports the development of new ethical frameworks",
      "Fosters interdisciplinary collaboration",
    ],
    benefits: [
      "Learn to address ethical challenges in AI",
      "Explore how AI impacts philosophical questions",
      "Connect with a global network of philosophers",
      "Develop skills in AI-driven ethical analysis",
      "Understand the role of AI in shaping human values",
      "Participate in workshops on AI and philosophy",
      "Gain insights into the future of philosophy and technology",
      "Enhance your research with AI tools",
      "Promote interdisciplinary solutions to ethical challenges",
      "Earn certifications in AI-integrated philosophy studies",
    ],
    features: [
      "AI-Powered Ethical Analysis: Address real-world challenges",
      "Philosophy and Technology Integration: Explore new dimensions",
      "Expert-Led Training: Learn from leading philosophers and AI experts",
      "Workshops and Seminars: Hands-on learning experiences",
      "Global Collaboration: Connect with philosophers worldwide",
      "Custom Learning Paths: Tailored to your interests",
      "Cutting-Edge Curriculum: Stay updated on emerging theories",
      "Networking Opportunities: Build global connections",
      "Certification Programs: Advance your career with credentials",
      "Real-World Applications: Solve ethical dilemmas using AI",
    ],
  },
  "AI Board of Economics": {
    heading: "AI Board of Economics",
    mainHeading: "Redefining Economics with Artificial Intelligence",
    summary: `
        The AI Board of Economics uses AI to analyze and predict economic trends, enabling better decision-making in 
        policy, business, and finance. By integrating machine learning, big data, and economic theory, the board offers 
        innovative tools to solve complex economic challenges and understand global markets.
      `,
    highlights: [
      "Combines economics with AI-driven tools",
      "Analyzes global markets using big data",
      "Focuses on solving real-world economic challenges",
      "Offers training in AI and economic modeling",
      "Hosts workshops on AI applications in economics",
      "Supports interdisciplinary research in economics and AI",
      "Promotes innovative solutions for financial systems",
      "Uses AI to predict economic trends accurately",
      "Encourages sustainable economic policies",
      "Fosters global collaboration in economic research",
    ],
    benefits: [
      "Learn to use AI for economic modeling",
      "Analyze global markets with advanced tools",
      "Improve decision-making with AI-powered insights",
      "Connect with economists and AI experts globally",
      "Explore sustainable solutions to economic challenges",
      "Enhance career prospects in economics and AI",
      "Participate in workshops on AI in economics",
      "Develop skills in data-driven economic analysis",
      "Understand the impact of AI on financial systems",
      "Earn certifications in AI-integrated economics studies",
    ],
    features: [
      "AI-Driven Economic Tools: Enhance decision-making",
      "Big Data Analysis: Gain insights into global markets",
      "Expert-Led Training: Learn from leading economists",
      "Workshops and Seminars: Practical learning opportunities",
      "Global Collaboration: Connect with international economists",
      "Custom Learning Paths: Tailored programs to meet your goals",
      "Cutting-Edge Curriculum: Stay updated on trends",
      "Networking Opportunities: Build professional connections",
      "Certification Programs: Advance your career with recognition",
      "Real-World Applications: Solve economic challenges with AI",
    ],
  },
  "AI Board of International Relations": {
    heading: "AI Board of International Relations",
    mainHeading: "Enhancing Global Relations with Artificial Intelligence",
    summary: `
        The AI Board of International Relations combines the study of global politics with AI to analyze international 
        trends, conflicts, and policies. It offers tools and training to better understand diplomatic strategies, 
        geopolitical shifts, and the role of technology in shaping global relations.
      `,
    highlights: [
      "Integrates AI with international relations studies",
      "Analyzes geopolitical trends with advanced tools",
      "Focuses on real-world applications in diplomacy",
      "Provides training in AI and global strategy",
      "Hosts workshops on AI in international relations",
      "Supports global collaboration among policymakers",
      "Promotes innovative solutions to global challenges",
      "Uses AI to study international conflicts and policies",
      "Encourages sustainable global relations strategies",
      "Develops frameworks for tech-driven diplomacy",
    ],
    benefits: [
      "Learn to analyze international trends with AI tools",
      "Gain insights into global politics and strategies",
      "Connect with a global network of policymakers",
      "Enhance diplomatic skills with AI-driven insights",
      "Explore solutions to real-world global challenges",
      "Improve career prospects in international relations",
      "Participate in workshops on AI in diplomacy",
      "Develop skills in data analysis for global trends",
      "Understand the role of AI in shaping diplomacy",
      "Earn certifications in AI-integrated international relations studies",
    ],
    features: [
      "AI-Powered Analysis: Study geopolitical trends efficiently",
      "Diplomatic Strategy Tools: Enhance global relations skills",
      "Expert-Led Training: Learn from international relations experts",
      "Workshops and Seminars: Hands-on learning opportunities",
      "Global Collaboration: Network with policymakers worldwide",
      "Real-World Applications: Address contemporary global challenges",
      "Custom Learning Paths: Tailored to your career goals",
      "Cutting-Edge Curriculum: Stay updated on international trends",
      "Networking Opportunities: Build global connections",
      "Certification Programs: Advance your career in diplomacy and AI",
    ],
  },
  "AI Board of Criminology": {
    heading: "AI Board of Criminology",
    mainHeading: "Revolutionizing Crime Studies with Artificial Intelligence",
    summary: `
        The AI Board of Criminology uses artificial intelligence to analyze and predict crime patterns, 
        helping create safer communities. It integrates data analytics, machine learning, and criminology 
        to study criminal behavior, develop prevention strategies, and enhance law enforcement practices. 
        This board provides tools and training to empower students, researchers, and professionals in 
        tackling modern crime challenges with cutting-edge technology.
      `,
    highlights: [
      "Integrates AI with criminology research",
      "Focuses on crime pattern analysis and prevention",
      "Offers training in AI-powered forensic tools",
      "Supports global collaboration in crime studies",
      "Promotes ethical AI use in law enforcement",
      "Hosts workshops on AI and criminal behavior",
      "Enhances research with advanced data analytics",
      "Develops strategies for crime prevention",
      "Explores real-world applications of AI in criminology",
      "Encourages interdisciplinary research in crime studies",
    ],
    benefits: [
      "Learn to analyze crime patterns using AI",
      "Improve research with advanced forensic tools",
      "Develop innovative crime prevention strategies",
      "Gain insights into modern criminal behavior",
      "Enhance career prospects in criminology and AI",
      "Connect with global experts in crime studies",
      "Participate in workshops led by criminology experts",
      "Explore ethical challenges in AI and crime studies",
      "Understand the role of AI in law enforcement",
      "Earn certifications in AI-integrated criminology",
    ],
    features: [
      "AI-Driven Crime Analysis: Enhance research efficiency",
      "Data Analytics for Crime Studies: Identify patterns and trends",
      "Expert-Led Training: Learn from criminology and AI specialists",
      "Workshops and Seminars: Practical, hands-on learning",
      "Global Collaboration: Build networks with international experts",
      "Real-World Applications: Solve contemporary crime challenges",
      "Ethical AI Integration: Address ethical dilemmas in crime research",
      "Custom Learning Paths: Tailored programs for diverse interests",
      "Cutting-Edge Curriculum: Stay updated with innovations",
      "Certification Programs: Advance your career with recognized credentials",
    ],
  },
  "AI Board of Media Studies": {
    heading: "AI Board of Media Studies",
    mainHeading: "Transforming Media Studies with Artificial Intelligence",
    summary: `
        The AI Board of Media Studies bridges the gap between media and technology, offering insights 
        into how artificial intelligence is reshaping communication, journalism, and digital media. 
        It trains students and professionals in using AI tools for content creation, audience analysis, 
        and digital strategy. The board empowers participants to stay ahead in the ever-evolving world 
        of media and technology.
      `,
    highlights: [
      "Combines AI with traditional media studies",
      "Focuses on AI-driven audience analysis",
      "Offers training in digital media strategies",
      "Promotes ethical AI use in media and journalism",
      "Hosts workshops on AI in content creation",
      "Supports global collaboration among media experts",
      "Explores innovative storytelling techniques with AI",
      "Integrates data analytics in media studies",
      "Develops strategies for digital content optimization",
      "Encourages interdisciplinary research in media and AI",
    ],
    benefits: [
      "Learn to use AI tools for content creation",
      "Understand audience behavior through AI analytics",
      "Enhance career prospects in media and technology",
      "Gain insights into ethical AI use in media",
      "Develop innovative digital strategies with AI",
      "Connect with a global network of media professionals",
      "Participate in workshops on AI and journalism",
      "Explore the impact of AI on storytelling",
      "Stay updated with trends in digital media",
      "Earn certifications in AI-integrated media studies",
    ],
    features: [
      "AI-Powered Audience Insights: Understand audience preferences",
      "Digital Content Optimization: Enhance media performance",
      "Expert-Led Training: Learn from media and AI experts",
      "Workshops and Seminars: Practical training sessions",
      "Global Collaboration: Network with international media professionals",
      "Real-World Applications: Solve challenges in digital media",
      "Custom Learning Paths: Tailored to your career goals",
      "Cutting-Edge Curriculum: Stay ahead in media innovations",
      "Networking Opportunities: Build connections in the industry",
      "Certification Programs: Boost your credentials in media studies",
    ],
  },
  "AI Board of Cultural Studies": {
    heading: "AI Board of Cultural Studies",
    mainHeading: "Exploring Cultures with Artificial Intelligence",
    summary: `
        The AI Board of Cultural Studies leverages artificial intelligence to study cultural trends, 
        traditions, and global interactions. By combining AI and cultural research, this board helps 
        students and researchers understand diverse societies and address cultural challenges in 
        innovative ways. It empowers participants to use AI for promoting cross-cultural understanding 
        and social harmony.
      `,
    highlights: [
      "Integrates AI with cultural research",
      "Focuses on analyzing global cultural trends",
      "Promotes cross-cultural understanding through AI",
      "Provides training in AI-powered cultural tools",
      "Supports interdisciplinary research in cultural studies",
      "Hosts workshops on AI in cultural analysis",
      "Explores the impact of AI on traditions and societies",
      "Develops strategies for addressing cultural challenges",
      "Encourages ethical AI use in cultural studies",
      "Fosters global collaboration among cultural researchers",
    ],
    benefits: [
      "Learn to analyze cultural trends with AI tools",
      "Gain insights into global cultural dynamics",
      "Connect with experts in cultural studies and AI",
      "Enhance your research with AI-powered tools",
      "Promote cross-cultural understanding through technology",
      "Develop innovative solutions to cultural challenges",
      "Participate in workshops on AI and cultural studies",
      "Stay updated with trends in cultural research",
      "Understand the ethical use of AI in cultural contexts",
      "Earn certifications in AI-integrated cultural studies",
    ],
    features: [
      "AI-Powered Cultural Insights: Analyze cultural trends",
      "Global Cultural Analysis: Explore diverse societies",
      "Expert-Led Training: Learn from cultural and AI specialists",
      "Workshops and Seminars: Practical learning opportunities",
      "Real-World Applications: Solve cultural challenges with AI",
      "Custom Learning Paths: Tailored programs to suit interests",
      "Cutting-Edge Curriculum: Stay ahead in cultural research",
      "Networking Opportunities: Build global connections",
      "Ethical AI Integration: Address cultural and ethical challenges",
      "Certification Programs: Advance your career in cultural studies",
    ],
  },
  "AI Board of Archaeology": {
    heading: "AI Board of Archaeology",
    mainHeading: "Uncovering History with Artificial Intelligence",
    summary: `
        The AI Board of Archaeology combines traditional archaeological research with advanced AI tools 
        to uncover and analyze historical artifacts, sites, and patterns. This board empowers students 
        and professionals to use AI for studying ancient civilizations, preserving history, and solving 
        archaeological mysteries efficiently and accurately.
      `,
    highlights: [
      "Combines AI with traditional archaeology",
      "Focuses on artifact analysis and site discovery",
      "Offers training in AI-powered archaeological tools",
      "Supports global collaboration in archaeology research",
      "Promotes ethical AI use in archaeological studies",
      "Hosts workshops on AI in historical preservation",
      "Explores real-world applications of AI in archaeology",
      "Enhances research efficiency with advanced tools",
      "Develops strategies for preserving historical sites",
      "Encourages interdisciplinary research in archaeology",
    ],
    benefits: [
      "Learn to analyze historical artifacts using AI",
      "Improve efficiency in archaeological research",
      "Gain insights into ancient civilizations with AI tools",
      "Connect with global experts in archaeology",
      "Participate in workshops on AI and historical preservation",
      "Enhance career prospects in archaeology and AI",
      "Explore the ethical use of AI in archaeology",
      "Stay updated with trends in archaeological research",
      "Understand the impact of AI on historical studies",
      "Earn certifications in AI-integrated archaeology",
    ],
    features: [
      "AI-Powered Artifact Analysis: Study history efficiently",
      "Site Discovery Tools: Uncover hidden historical treasures",
      "Expert-Led Training: Learn from archaeologists and AI specialists",
      "Workshops and Seminars: Hands-on learning experiences",
      "Global Collaboration: Connect with archaeologists worldwide",
      "Real-World Applications: Address archaeological challenges with AI",
      "Custom Learning Paths: Tailored programs for unique interests",
      "Cutting-Edge Curriculum: Stay updated on innovations",
      "Networking Opportunities: Build connections in archaeology",
      "Certification Programs: Advance your career in archaeology",
    ],
  },
  "AI Board of Human Rights": {
    heading: "AI Board of Human Rights",
    mainHeading: "Advancing Human Rights with Artificial Intelligence",
    summary: `
        The AI Board of Human Rights uses artificial intelligence to address global human rights 
        challenges. By integrating AI tools and human rights studies, this board empowers participants 
        to analyze human rights data, identify violations, and develop strategies for promoting equality 
        and justice worldwide.
      `,
    highlights: [
      "Integrates AI with human rights studies",
      "Focuses on analyzing global human rights data",
      "Promotes strategies for equality and justice using AI",
      "Provides training in AI-powered human rights tools",
      "Supports interdisciplinary research in human rights",
      "Hosts workshops on AI in human rights advocacy",
      "Explores real-world applications of AI in justice systems",
      "Develops strategies for addressing human rights violations",
      "Encourages ethical AI use in human rights studies",
      "Fosters global collaboration among human rights experts",
    ],
    benefits: [
      "Learn to analyze human rights data with AI tools",
      "Gain insights into global human rights issues",
      "Connect with experts in human rights and AI",
      "Enhance your advocacy skills with AI-driven insights",
      "Promote justice and equality using technology",
      "Participate in workshops on AI and human rights",
      "Explore solutions to human rights challenges",
      "Stay updated with trends in human rights research",
      "Understand the ethical use of AI in human rights contexts",
      "Earn certifications in AI-integrated human rights studies",
    ],
    features: [
      "AI-Powered Human Rights Analysis: Study global trends",
      "Justice Advocacy Tools: Develop impactful strategies",
      "Expert-Led Training: Learn from human rights and AI specialists",
      "Workshops and Seminars: Practical learning opportunities",
      "Real-World Applications: Address global human rights challenges",
      "Custom Learning Paths: Tailored programs for individual goals",
      "Cutting-Edge Curriculum: Stay ahead in human rights studies",
      "Networking Opportunities: Build global connections",
      "Ethical AI Integration: Promote justice responsibly",
      "Certification Programs: Advance your career in human rights",
    ],
  },
  "AI Board of Social Work": {
    heading: "AI Board of Social Work",
    mainHeading: "Revolutionizing Social Work with Artificial Intelligence",
    summary: `
        The AI Board of Social Work empowers professionals and students to address social challenges 
        using artificial intelligence. By integrating AI tools, data analytics, and social work practices, 
        the board helps tackle issues like poverty, mental health, and community development. This innovative 
        approach enhances the impact of social work and equips participants with the skills needed for modern 
        solutions.
      `,
    highlights: [
      "Integrates AI with social work practices",
      "Focuses on tackling social challenges like poverty and mental health",
      "Provides training in AI-powered social tools",
      "Hosts workshops on AI in social work",
      "Promotes community development through technology",
      "Supports global collaboration among social workers",
      "Encourages ethical AI use in social initiatives",
      "Explores real-world applications of AI in social work",
      "Develops strategies for effective social interventions",
      "Fosters interdisciplinary research in social work and AI",
    ],
    benefits: [
      "Learn to use AI tools for social impact",
      "Address modern social challenges effectively",
      "Connect with global experts in social work and AI",
      "Enhance your skills in community development",
      "Promote sustainable social change through technology",
      "Participate in workshops on AI and social initiatives",
      "Stay updated with trends in social work research",
      "Explore ethical challenges in AI-powered social work",
      "Improve your career prospects in social work and AI",
      "Earn certifications in AI-integrated social work practices",
    ],
    features: [
      "AI-Driven Social Analysis: Enhance problem-solving",
      "Community Development Tools: Build impactful solutions",
      "Expert-Led Training: Learn from social work and AI specialists",
      "Workshops and Seminars: Hands-on learning opportunities",
      "Global Collaboration: Connect with social workers worldwide",
      "Real-World Applications: Address contemporary social challenges",
      "Ethical AI Integration: Promote responsible technology use",
      "Custom Learning Paths: Tailored to your career goals",
      "Cutting-Edge Curriculum: Stay ahead in social work innovations",
      "Certification Programs: Advance your career with recognized credentials",
    ],
  },
  "AI Board of Gender Studies": {
    heading: "AI Board of Gender Studies",
    mainHeading: "Exploring Gender Dynamics with Artificial Intelligence",
    summary: `
        The AI Board of Gender Studies uses artificial intelligence to analyze gender dynamics, 
        promote equality, and address societal challenges. By combining AI tools and gender studies, 
        the board empowers students and professionals to understand and advocate for gender justice in 
        innovative and impactful ways.
      `,
    highlights: [
      "Integrates AI with gender studies research",
      "Focuses on analyzing gender dynamics globally",
      "Promotes strategies for gender equality using AI",
      "Provides training in AI-powered gender analysis tools",
      "Hosts workshops on AI and gender studies",
      "Supports interdisciplinary research on gender issues",
      "Encourages ethical AI use in addressing gender disparities",
      "Explores real-world applications of AI in gender advocacy",
      "Develops strategies for promoting gender justice",
      "Fosters global collaboration among gender researchers",
    ],
    benefits: [
      "Learn to analyze gender dynamics with AI tools",
      "Promote gender justice through innovative solutions",
      "Connect with global experts in gender studies",
      "Enhance your advocacy skills with AI insights",
      "Explore ethical challenges in AI and gender issues",
      "Participate in workshops on gender equality and AI",
      "Stay updated with trends in gender studies research",
      "Understand the role of AI in addressing societal challenges",
      "Improve career prospects in gender studies and AI",
      "Earn certifications in AI-integrated gender studies",
    ],
    features: [
      "AI-Powered Gender Analysis: Study global trends efficiently",
      "Advocacy Tools for Gender Equality: Promote impactful solutions",
      "Expert-Led Training: Learn from specialists in gender studies and AI",
      "Workshops and Seminars: Hands-on learning experiences",
      "Global Collaboration: Connect with gender researchers worldwide",
      "Real-World Applications: Address gender disparities effectively",
      "Ethical AI Integration: Promote justice responsibly",
      "Custom Learning Paths: Tailored to individual goals",
      "Cutting-Edge Curriculum: Stay ahead in gender studies innovations",
      "Certification Programs: Boost your career in gender studies",
    ],
  },
  "AI Board of Peace and Conflict Studies": {
    heading: "AI Board of Peace and Conflict Studies",
    mainHeading: "Advancing Peace with Artificial Intelligence",
    summary: `
        The AI Board of Peace and Conflict Studies leverages artificial intelligence to analyze and 
        resolve conflicts, promote peace, and address global security challenges. By integrating AI 
        tools and conflict studies, this board equips students and professionals with the skills needed 
        to create sustainable peace and prevent conflicts effectively.
      `,
    highlights: [
      "Integrates AI with peace and conflict studies",
      "Focuses on conflict resolution and peacebuilding strategies",
      "Provides training in AI-powered conflict analysis tools",
      "Hosts workshops on AI in peace studies",
      "Promotes sustainable peace through technology",
      "Supports interdisciplinary research in conflict studies",
      "Encourages ethical AI use in global security challenges",
      "Explores real-world applications of AI in conflict resolution",
      "Develops strategies for preventing conflicts",
      "Fosters global collaboration among peace researchers",
    ],
    benefits: [
      "Learn to analyze conflicts with AI tools",
      "Promote sustainable peace through innovative solutions",
      "Connect with global experts in peace and conflict studies",
      "Enhance your conflict resolution skills with AI insights",
      "Explore ethical challenges in AI-powered peacebuilding",
      "Participate in workshops on peace studies and AI",
      "Stay updated with trends in conflict studies research",
      "Understand the role of AI in addressing global security issues",
      "Improve career prospects in peace and conflict studies",
      "Earn certifications in AI-integrated peace studies",
    ],
    features: [
      "AI-Powered Conflict Analysis: Study trends effectively",
      "Peacebuilding Tools: Develop impactful strategies",
      "Expert-Led Training: Learn from specialists in peace studies and AI",
      "Workshops and Seminars: Practical learning opportunities",
      "Global Collaboration: Build networks with peace researchers",
      "Real-World Applications: Address conflicts using AI",
      "Ethical AI Integration: Promote peace responsibly",
      "Custom Learning Paths: Tailored to career goals",
      "Cutting-Edge Curriculum: Stay updated in peace studies",
      "Certification Programs: Advance your career in conflict studies",
    ],
  },
  "AI Board of Constitutional Law": {
    heading: "AI Board of Constitutional Law",
    mainHeading: "Transforming Constitutional Law with Artificial Intelligence",
    summary: `
        The AI Board of Constitutional Law applies artificial intelligence to analyze legal systems, 
        interpret constitutions, and promote justice. By integrating AI tools and constitutional law, 
        the board empowers students, legal professionals, and policymakers to create fairer and more 
        efficient legal systems worldwide.
      `,
    highlights: [
      "Integrates AI with constitutional law research",
      "Focuses on analyzing legal systems and constitutions",
      "Provides training in AI-powered legal tools",
      "Hosts workshops on AI and constitutional law",
      "Promotes justice and equality through technology",
      "Supports interdisciplinary research in law and AI",
      "Encourages ethical AI use in legal systems",
      "Explores real-world applications of AI in constitutional law",
      "Develops strategies for improving legal systems",
      "Fosters global collaboration among legal experts",
    ],
    benefits: [
      "Learn to analyze constitutions with AI tools",
      "Promote justice and equality through technology",
      "Connect with global experts in constitutional law",
      "Enhance your legal skills with AI insights",
      "Explore ethical challenges in AI-powered legal systems",
      "Participate in workshops on constitutional law and AI",
      "Stay updated with trends in legal research",
      "Understand the role of AI in addressing global legal challenges",
      "Improve career prospects in law and technology",
      "Earn certifications in AI-integrated constitutional law",
    ],
    features: [
      "AI-Powered Legal Analysis: Study systems efficiently",
      "Constitutional Insights: Develop impactful strategies",
      "Expert-Led Training: Learn from legal and AI specialists",
      "Workshops and Seminars: Practical learning experiences",
      "Global Collaboration: Build networks with legal experts",
      "Real-World Applications: Address legal challenges effectively",
      "Ethical AI Integration: Promote justice responsibly",
      "Custom Learning Paths: Tailored to individual goals",
      "Cutting-Edge Curriculum: Stay ahead in constitutional law innovations",
      "Certification Programs: Boost your career in law",
    ],
  },
  "AI Board of International Law": {
    heading: "AI Board of International Law",
    mainHeading: "Enhancing International Law with Artificial Intelligence",
    summary: `
        The AI Board of International Law combines artificial intelligence with international legal 
        studies to analyze treaties, resolve disputes, and address global legal challenges. By integrating 
        AI tools, this board equips legal professionals and students with innovative solutions for 
        international law practices and global justice.
      `,
    highlights: [
      "Integrates AI with international legal studies",
      "Focuses on analyzing treaties and resolving disputes",
      "Provides training in AI-powered international law tools",
      "Hosts workshops on AI and global legal challenges",
      "Promotes justice and fairness in international systems",
      "Supports interdisciplinary research in law and AI",
      "Encourages ethical AI use in global legal frameworks",
      "Explores real-world applications of AI in international law",
      "Develops strategies for addressing international disputes",
      "Fosters global collaboration among legal professionals",
    ],
    benefits: [
      "Learn to analyze treaties with AI tools",
      "Promote global justice through innovative solutions",
      "Connect with experts in international law and AI",
      "Enhance your skills in global legal practices",
      "Explore ethical challenges in AI and international law",
      "Participate in workshops on AI and global legal systems",
      "Stay updated with trends in international law research",
      "Understand the role of AI in addressing global disputes",
      "Improve career prospects in international law and technology",
      "Earn certifications in AI-integrated international law",
    ],
    features: [
      "AI-Powered Treaty Analysis: Study legal agreements effectively",
      "Dispute Resolution Tools: Develop impactful solutions",
      "Expert-Led Training: Learn from specialists in law and AI",
      "Workshops and Seminars: Practical learning opportunities",
      "Global Collaboration: Build networks with legal professionals",
      "Real-World Applications: Address international challenges with AI",
      "Ethical AI Integration: Promote justice responsibly",
      "Custom Learning Paths: Tailored to career goals",
      "Cutting-Edge Curriculum: Stay ahead in legal innovations",
      "Certification Programs: Advance your career in international law",
    ],
  },
  "AI Board of Cyber Law": {
    heading: "AI Board of Cyber Law",
    mainHeading: "Shaping Cyber Law with Artificial Intelligence",
    summary: `
        The AI Board of Cyber Law combines artificial intelligence with legal expertise to address challenges 
        in the digital world. It focuses on topics like online privacy, data protection, cybersecurity, and 
        digital rights. By integrating AI tools and cyber law studies, the board equips students and professionals 
        to understand and resolve complex legal issues in the digital age.
      `,
    highlights: [
      "Integrates AI with cyber law studies",
      "Focuses on data protection and online privacy",
      "Provides training in AI-powered legal tools",
      "Hosts workshops on cybersecurity and digital rights",
      "Explores real-world applications of AI in cyber law",
      "Promotes ethical AI use in the digital world",
      "Develops strategies for addressing cybercrime",
      "Supports interdisciplinary research in cyber law",
      "Encourages global collaboration among legal professionals",
      "Fosters innovation in resolving digital legal challenges",
    ],
    benefits: [
      "Learn to analyze digital legal issues with AI tools",
      "Understand online privacy and data protection laws",
      "Develop solutions to combat cybercrime effectively",
      "Enhance career prospects in cyber law and AI",
      "Connect with global experts in digital legal studies",
      "Participate in workshops on AI and cybersecurity",
      "Explore ethical challenges in the digital world",
      "Stay updated with trends in cyber law research",
      "Promote responsible use of technology through law",
      "Earn certifications in AI-integrated cyber law practices",
    ],
    features: [
      "AI-Powered Cyber Analysis: Address digital challenges effectively",
      "Cybersecurity Legal Tools: Develop impactful strategies",
      "Expert-Led Training: Learn from cyber law and AI specialists",
      "Workshops and Seminars: Practical learning experiences",
      "Global Collaboration: Network with legal experts worldwide",
      "Real-World Applications: Solve contemporary digital legal issues",
      "Ethical AI Integration: Promote fairness and justice",
      "Custom Learning Paths: Tailored to your career goals",
      "Cutting-Edge Curriculum: Stay ahead in cyber law innovations",
      "Certification Programs: Advance your career in digital law",
    ],
  },
  "AI Board of Corporate Law": {
    heading: "AI Board of Corporate Law",
    mainHeading: "Revolutionizing Corporate Law with Artificial Intelligence",
    summary: `
        The AI Board of Corporate Law focuses on using artificial intelligence to streamline business 
        legal practices, analyze regulations, and resolve corporate disputes. It equips legal professionals 
        and students with tools to understand corporate governance, compliance, and financial regulations, 
        making the corporate world more efficient and transparent.
      `,
    highlights: [
      "Integrates AI with corporate law practices",
      "Focuses on corporate governance and compliance",
      "Provides training in AI-powered business legal tools",
      "Hosts workshops on corporate law and AI",
      "Promotes ethical AI use in corporate governance",
      "Explores real-world applications of AI in corporate disputes",
      "Supports interdisciplinary research in corporate law",
      "Develops strategies for enhancing business compliance",
      "Encourages global collaboration among legal professionals",
      "Fosters innovation in corporate legal solutions",
    ],
    benefits: [
      "Learn to use AI tools for corporate governance",
      "Understand compliance and financial regulations effectively",
      "Develop solutions to resolve corporate disputes",
      "Enhance career prospects in corporate law and AI",
      "Connect with global experts in business legal studies",
      "Participate in workshops on corporate law and AI tools",
      "Explore ethical challenges in corporate governance",
      "Stay updated with trends in corporate law research",
      "Promote transparency and efficiency in corporate practices",
      "Earn certifications in AI-integrated corporate law",
    ],
    features: [
      "AI-Powered Corporate Analysis: Streamline business legal processes",
      "Governance and Compliance Tools: Enhance business efficiency",
      "Expert-Led Training: Learn from corporate law specialists",
      "Workshops and Seminars: Hands-on learning opportunities",
      "Global Collaboration: Build networks with legal professionals",
      "Real-World Applications: Address corporate legal challenges",
      "Ethical AI Integration: Promote fairness in corporate practices",
      "Custom Learning Paths: Tailored to business law interests",
      "Cutting-Edge Curriculum: Stay ahead in corporate law innovations",
      "Certification Programs: Advance your career in corporate law",
    ],
  },
  "AI Board of Public Administration": {
    heading: "AI Board of Public Administration",
    mainHeading:
      "Transforming Public Administration with Artificial Intelligence",
    summary: `
        The AI Board of Public Administration uses artificial intelligence to enhance governance, 
        improve public services, and streamline administrative processes. By combining AI tools with 
        public administration practices, the board empowers professionals to create efficient and 
        citizen-focused government systems.
      `,
    highlights: [
      "Integrates AI with public administration practices",
      "Focuses on improving governance and public services",
      "Provides training in AI-powered administrative tools",
      "Hosts workshops on AI and governance",
      "Promotes transparency and efficiency in public systems",
      "Explores real-world applications of AI in administration",
      "Supports interdisciplinary research in governance and AI",
      "Develops strategies for modern public management",
      "Encourages global collaboration among public administrators",
      "Fosters innovation in governance and policy implementation",
    ],
    benefits: [
      "Learn to use AI tools for public administration",
      "Improve public services with innovative solutions",
      "Connect with global experts in governance and AI",
      "Enhance career prospects in public management",
      "Promote transparency and citizen-focused systems",
      "Participate in workshops on AI in public administration",
      "Explore ethical challenges in governance and technology",
      "Stay updated with trends in public administration research",
      "Develop strategies for modern governance",
      "Earn certifications in AI-integrated public administration",
    ],
    features: [
      "AI-Powered Governance Tools: Improve administrative processes",
      "Citizen-Focused Solutions: Enhance public services",
      "Expert-Led Training: Learn from public administration specialists",
      "Workshops and Seminars: Hands-on learning experiences",
      "Global Collaboration: Build networks with administrators worldwide",
      "Real-World Applications: Address governance challenges effectively",
      "Ethical AI Integration: Promote responsible public systems",
      "Custom Learning Paths: Tailored to your career goals",
      "Cutting-Edge Curriculum: Stay ahead in public administration",
      "Certification Programs: Advance your career in public governance",
    ],
  },
  "AI Board of Governance and Policy Making": {
    heading: "AI Board of Governance and Policy Making",
    mainHeading: "Advancing Policy Making with Artificial Intelligence",
    summary: `
        The AI Board of Governance and Policy Making combines artificial intelligence with policy analysis 
        and governance to develop smarter strategies for societal progress. It focuses on using data-driven 
        insights and AI tools to create policies that address global challenges effectively and sustainably.
      `,
    highlights: [
      "Integrates AI with governance and policy analysis",
      "Focuses on data-driven policy development",
      "Provides training in AI-powered policy tools",
      "Hosts workshops on governance and AI",
      "Promotes sustainable and effective policy strategies",
      "Explores real-world applications of AI in governance",
      "Supports interdisciplinary research in policy making",
      "Develops strategies for addressing global challenges",
      "Encourages global collaboration among policymakers",
      "Fosters innovation in governance and public policies",
    ],
    benefits: [
      "Learn to create data-driven policies using AI",
      "Understand governance challenges and develop solutions",
      "Connect with global experts in policy making and AI",
      "Enhance career prospects in governance and policy analysis",
      "Promote sustainable strategies for societal progress",
      "Participate in workshops on governance and policy tools",
      "Explore ethical challenges in AI-powered policy making",
      "Stay updated with trends in governance research",
      "Develop innovative strategies for global challenges",
      "Earn certifications in AI-integrated policy making",
    ],
    features: [
      "AI-Powered Policy Tools: Develop smarter strategies",
      "Sustainable Governance Solutions: Address global challenges",
      "Expert-Led Training: Learn from governance and AI specialists",
      "Workshops and Seminars: Practical learning opportunities",
      "Global Collaboration: Build networks with policymakers",
      "Real-World Applications: Solve governance challenges effectively",
      "Ethical AI Integration: Promote fairness in policy making",
      "Custom Learning Paths: Tailored to your career goals",
      "Cutting-Edge Curriculum: Stay ahead in policy innovations",
      "Certification Programs: Advance your career in governance",
    ],
  },
  "AI Board of Environmental Law": {
    heading: "AI Board of Environmental Law",
    mainHeading: "Protecting the Planet with Artificial Intelligence",
    summary: `
        The AI Board of Environmental Law leverages artificial intelligence to address environmental 
        challenges and enforce legal frameworks for sustainability. It integrates AI tools with environmental 
        legal studies to promote sustainable development and protect natural resources effectively.
      `,
    highlights: [
      "Integrates AI with environmental legal studies",
      "Focuses on sustainability and natural resource protection",
      "Provides training in AI-powered environmental tools",
      "Hosts workshops on environmental law and AI",
      "Promotes ethical AI use for environmental protection",
      "Explores real-world applications of AI in sustainability",
      "Supports interdisciplinary research in environmental law",
      "Develops strategies for addressing climate change",
      "Encourages global collaboration among environmental lawyers",
      "Fosters innovation in sustainable legal solutions",
    ],
    benefits: [
      "Learn to analyze environmental issues with AI tools",
      "Develop strategies for sustainable development",
      "Connect with global experts in environmental law",
      "Enhance your skills in sustainability practices",
      "Promote ethical AI use in protecting the planet",
      "Participate in workshops on environmental law and AI",
      "Stay updated with trends in environmental law research",
      "Address climate change challenges effectively",
      "Improve career prospects in environmental law and AI",
      "Earn certifications in AI-integrated environmental law",
    ],
    features: [
      "AI-Powered Environmental Tools: Protect natural resources",
      "Sustainability Solutions: Address climate challenges",
      "Expert-Led Training: Learn from environmental law specialists",
      "Workshops and Seminars: Practical learning opportunities",
      "Global Collaboration: Build networks with environmental lawyers",
      "Real-World Applications: Address global environmental issues",
      "Ethical AI Integration: Promote fairness in sustainability",
      "Custom Learning Paths: Tailored to your career goals",
      "Cutting-Edge Curriculum: Stay ahead in environmental law",
      "Certification Programs: Advance your career in sustainability law",
    ],
  },
  "AI Board of Taxation Law": {
    heading: "AI Board of Taxation Law",
    mainHeading: "Revolutionizing Taxation Law with Artificial Intelligence",
    summary: `
        The AI Board of Taxation Law uses artificial intelligence to simplify tax regulations, enhance 
        compliance, and resolve legal disputes related to taxation. By integrating AI tools with taxation law, 
        the board empowers students and professionals to understand and manage complex tax systems 
        efficiently while promoting fairness and transparency.
      `,
    highlights: [
      "Integrates AI with taxation law practices",
      "Focuses on simplifying tax regulations and compliance",
      "Provides training in AI-powered tax analysis tools",
      "Hosts workshops on taxation law and AI",
      "Promotes ethical AI use in tax systems",
      "Explores real-world applications of AI in tax compliance",
      "Supports interdisciplinary research in taxation law",
      "Develops strategies for resolving tax disputes",
      "Encourages global collaboration among tax professionals",
      "Fosters innovation in tax management solutions",
    ],
    benefits: [
      "Learn to use AI tools for tax compliance",
      "Simplify complex tax regulations efficiently",
      "Connect with global experts in taxation law",
      "Enhance career prospects in tax law and AI",
      "Promote transparency and fairness in tax systems",
      "Participate in workshops on tax law and AI tools",
      "Explore ethical challenges in taxation law and technology",
      "Stay updated with trends in tax law research",
      "Develop innovative solutions for tax management",
      "Earn certifications in AI-integrated taxation law",
    ],
    features: [
      "AI-Powered Tax Analysis: Simplify compliance processes",
      "Tax Dispute Resolution Tools: Resolve legal issues efficiently",
      "Expert-Led Training: Learn from tax law specialists",
      "Workshops and Seminars: Practical learning opportunities",
      "Global Collaboration: Build networks with tax professionals",
      "Real-World Applications: Address taxation challenges effectively",
      "Ethical AI Integration: Promote justice in tax systems",
      "Custom Learning Paths: Tailored to your career goals",
      "Cutting-Edge Curriculum: Stay ahead in taxation law",
      "Certification Programs: Advance your career in tax management",
    ],
  },
  "AI Board of Space Law": {
    heading: "AI Board of Space Law",
    mainHeading: "Advancing Space Law with Artificial Intelligence",
    summary: `
        The AI Board of Space Law combines artificial intelligence with legal expertise to address issues 
        related to outer space, such as satellite regulations, space exploration, and international treaties. 
        It equips students and professionals with the knowledge and tools to navigate the evolving legal 
        landscape of space governance.
      `,
    highlights: [
      "Integrates AI with space law studies",
      "Focuses on satellite regulations and space governance",
      "Provides training in AI-powered legal tools for space",
      "Hosts workshops on space law and AI",
      "Promotes ethical AI use in outer space activities",
      "Explores real-world applications of AI in space exploration",
      "Supports interdisciplinary research in space law",
      "Develops strategies for international space treaties",
      "Encourages global collaboration among space law experts",
      "Fosters innovation in space governance solutions",
    ],
    benefits: [
      "Learn to analyze space regulations with AI tools",
      "Understand international treaties and space governance",
      "Connect with global experts in space law and AI",
      "Enhance career prospects in the space industry and law",
      "Promote responsible use of AI in outer space activities",
      "Participate in workshops on space law and AI",
      "Explore ethical challenges in space exploration",
      "Stay updated with trends in space law research",
      "Develop strategies for sustainable space governance",
      "Earn certifications in AI-integrated space law",
    ],
    features: [
      "AI-Powered Space Analysis: Address legal challenges efficiently",
      "Satellite Regulation Tools: Enhance compliance processes",
      "Expert-Led Training: Learn from specialists in space law",
      "Workshops and Seminars: Practical learning opportunities",
      "Global Collaboration: Build networks with space law professionals",
      "Real-World Applications: Solve outer space legal issues",
      "Ethical AI Integration: Promote fairness in space governance",
      "Custom Learning Paths: Tailored to individual interests",
      "Cutting-Edge Curriculum: Stay ahead in space law innovations",
      "Certification Programs: Advance your career in space governance",
    ],
  },
  "AI Board of Climate Change": {
    heading: "AI Board of Climate Change",
    mainHeading: "Addressing Climate Change with Artificial Intelligence",
    summary: `
        The AI Board of Climate Change leverages artificial intelligence to analyze environmental data, 
        predict climate patterns, and develop strategies to combat global warming. By combining AI tools 
        and climate science, the board empowers participants to create impactful solutions for a sustainable 
        future.
      `,
    highlights: [
      "Integrates AI with climate science research",
      "Focuses on analyzing and predicting climate patterns",
      "Provides training in AI-powered environmental tools",
      "Hosts workshops on climate change and AI",
      "Promotes sustainable solutions through technology",
      "Explores real-world applications of AI in combating global warming",
      "Supports interdisciplinary research on climate change",
      "Develops strategies for reducing carbon emissions",
      "Encourages global collaboration among climate experts",
      "Fosters innovation in addressing environmental challenges",
    ],
    benefits: [
      "Learn to analyze climate data using AI tools",
      "Develop strategies for reducing carbon emissions",
      "Connect with global experts in climate change and AI",
      "Enhance career prospects in environmental science and AI",
      "Promote sustainable development through technology",
      "Participate in workshops on AI and climate change",
      "Explore ethical challenges in environmental technology",
      "Stay updated with trends in climate science research",
      "Address global warming challenges effectively",
      "Earn certifications in AI-integrated climate studies",
    ],
    features: [
      "AI-Powered Climate Analysis: Understand environmental trends",
      "Sustainable Solutions Tools: Develop impactful strategies",
      "Expert-Led Training: Learn from climate science specialists",
      "Workshops and Seminars: Practical learning opportunities",
      "Global Collaboration: Build networks with environmental researchers",
      "Real-World Applications: Combat global warming effectively",
      "Ethical AI Integration: Promote responsible technology use",
      "Custom Learning Paths: Tailored to your career goals",
      "Cutting-Edge Curriculum: Stay ahead in climate science",
      "Certification Programs: Advance your career in environmental studies",
    ],
  },
  "AI Board of Sustainable Development": {
    heading: "AI Board of Sustainable Development",
    mainHeading: "Driving Sustainability with Artificial Intelligence",
    summary: `
        The AI Board of Sustainable Development uses artificial intelligence to promote eco-friendly 
        solutions, efficient resource management, and sustainable growth. By integrating AI tools and 
        sustainable development principles, the board empowers participants to create impactful strategies 
        for a better planet.
      `,
    highlights: [
      "Integrates AI with sustainable development principles",
      "Focuses on resource management and eco-friendly solutions",
      "Provides training in AI-powered sustainability tools",
      "Hosts workshops on sustainable development and AI",
      "Promotes ethical AI use in sustainability practices",
      "Explores real-world applications of AI in green technology",
      "Supports interdisciplinary research in sustainability",
      "Develops strategies for achieving global sustainability goals",
      "Encourages global collaboration among sustainability experts",
      "Fosters innovation in sustainable development solutions",
    ],
    benefits: [
      "Learn to manage resources efficiently using AI tools",
      "Develop eco-friendly solutions for sustainability",
      "Connect with global experts in sustainable development",
      "Enhance career prospects in sustainability and AI",
      "Promote ethical practices for a greener planet",
      "Participate in workshops on AI and sustainability",
      "Explore challenges in implementing sustainable solutions",
      "Stay updated with trends in green technology",
      "Address global sustainability goals effectively",
      "Earn certifications in AI-integrated sustainable development",
    ],
    features: [
      "AI-Powered Sustainability Tools: Develop green strategies",
      "Resource Management Solutions: Promote efficient practices",
      "Expert-Led Training: Learn from sustainability specialists",
      "Workshops and Seminars: Hands-on learning opportunities",
      "Global Collaboration: Build networks with green technology experts",
      "Real-World Applications: Solve environmental challenges",
      "Ethical AI Integration: Promote fairness in sustainability",
      "Custom Learning Paths: Tailored to individual goals",
      "Cutting-Edge Curriculum: Stay ahead in sustainability innovations",
      "Certification Programs: Advance your career in green practices",
    ],
  },
  "AI Board of Wildlife Conservation": {
    heading: "AI Board of Wildlife Conservation",
    mainHeading: "Protecting Wildlife with Artificial Intelligence",
    summary: `
        The AI Board of Wildlife Conservation combines artificial intelligence with conservation practices 
        to protect endangered species and preserve natural habitats. By integrating AI tools and wildlife 
        research, the board empowers participants to create effective strategies for biodiversity conservation.
      `,
    highlights: [
      "Integrates AI with wildlife conservation practices",
      "Focuses on protecting endangered species and habitats",
      "Provides training in AI-powered wildlife monitoring tools",
      "Hosts workshops on conservation and AI",
      "Promotes ethical AI use in biodiversity preservation",
      "Explores real-world applications of AI in wildlife protection",
      "Supports interdisciplinary research in conservation",
      "Develops strategies for combating habitat destruction",
      "Encourages global collaboration among conservationists",
      "Fosters innovation in wildlife protection solutions",
    ],
    benefits: [
      "Learn to monitor wildlife with AI tools",
      "Protect endangered species effectively",
      "Connect with global experts in conservation and AI",
      "Enhance career prospects in wildlife conservation",
      "Promote ethical practices in biodiversity preservation",
      "Participate in workshops on AI and conservation",
      "Explore challenges in protecting natural habitats",
      "Stay updated with trends in wildlife conservation",
      "Address habitat destruction issues effectively",
      "Earn certifications in AI-integrated wildlife conservation",
    ],
    features: [
      "AI-Powered Monitoring Tools: Track wildlife efficiently",
      "Habitat Preservation Solutions: Protect natural environments",
      "Expert-Led Training: Learn from wildlife conservationists",
      "Workshops and Seminars: Hands-on learning opportunities",
      "Global Collaboration: Build networks with conservation experts",
      "Real-World Applications: Solve biodiversity challenges",
      "Ethical AI Integration: Promote fairness in conservation",
      "Custom Learning Paths: Tailored to individual goals",
      "Cutting-Edge Curriculum: Stay ahead in wildlife protection",
      "Certification Programs: Advance your career in conservation",
    ],
  },
  "AI Board of Marine Biology": {
    heading: "AI Board of Marine Biology",
    mainHeading: "Exploring Ocean Life with Artificial Intelligence",
    summary: `
        The AI Board of Marine Biology combines artificial intelligence with marine research to 
        study ocean ecosystems, protect marine life, and address environmental challenges. By 
        integrating AI tools and marine biology practices, the board empowers students and researchers 
        to create innovative solutions for ocean conservation.
      `,
    highlights: [
      "Integrates AI with marine biology research",
      "Focuses on studying ocean ecosystems and marine life",
      "Provides training in AI-powered marine monitoring tools",
      "Hosts workshops on marine biology and AI",
      "Promotes ethical AI use in ocean conservation",
      "Explores real-world applications of AI in marine studies",
      "Supports interdisciplinary research in marine biology",
      "Develops strategies for protecting marine biodiversity",
      "Encourages global collaboration among marine researchers",
      "Fosters innovation in ocean ecosystem management",
    ],
    benefits: [
      "Learn to analyze marine ecosystems with AI tools",
      "Protect ocean life effectively with advanced technology",
      "Connect with global experts in marine biology and AI",
      "Enhance career prospects in marine science and AI",
      "Promote ethical practices in ocean conservation",
      "Participate in workshops on marine studies and AI",
      "Explore challenges in protecting marine biodiversity",
      "Stay updated with trends in ocean research",
      "Address environmental challenges in marine habitats",
      "Earn certifications in AI-integrated marine biology",
    ],
    features: [
      "AI-Powered Marine Monitoring Tools: Study ocean life efficiently",
      "Ecosystem Preservation Strategies: Protect marine habitats",
      "Expert-Led Training: Learn from marine biology specialists",
      "Workshops and Seminars: Hands-on learning opportunities",
      "Global Collaboration: Build networks with marine researchers",
      "Real-World Applications: Solve marine conservation challenges",
      "Ethical AI Integration: Promote responsible ocean research",
      "Custom Learning Paths: Tailored to individual goals",
      "Cutting-Edge Curriculum: Stay ahead in marine science",
      "Certification Programs: Advance your career in marine biology",
    ],
  },
  "AI Board of Environmental Engineering": {
    heading: "AI Board of Environmental Engineering",
    mainHeading:
      "Advancing Environmental Engineering with Artificial Intelligence",
    summary: `
        The AI Board of Environmental Engineering leverages artificial intelligence to develop 
        innovative solutions for environmental challenges such as pollution control, resource 
        management, and sustainable infrastructure. It equips engineers and researchers with 
        tools to create eco-friendly technologies and sustainable designs.
      `,
    highlights: [
      "Integrates AI with environmental engineering practices",
      "Focuses on pollution control and resource management",
      "Provides training in AI-powered engineering tools",
      "Hosts workshops on sustainable infrastructure and AI",
      "Promotes eco-friendly technologies through AI",
      "Explores real-world applications of AI in environmental engineering",
      "Supports interdisciplinary research in engineering and sustainability",
      "Develops strategies for reducing environmental impact",
      "Encourages global collaboration among environmental engineers",
      "Fosters innovation in sustainable engineering solutions",
    ],
    benefits: [
      "Learn to use AI tools for environmental engineering",
      "Develop eco-friendly solutions for sustainability",
      "Connect with global experts in engineering and AI",
      "Enhance career prospects in environmental engineering",
      "Promote sustainable infrastructure through innovative designs",
      "Participate in workshops on engineering and AI tools",
      "Explore challenges in pollution control and resource management",
      "Stay updated with trends in environmental engineering research",
      "Address environmental challenges effectively",
      "Earn certifications in AI-integrated environmental engineering",
    ],
    features: [
      "AI-Powered Engineering Tools: Build sustainable designs",
      "Pollution Control Strategies: Reduce environmental impact",
      "Expert-Led Training: Learn from engineering specialists",
      "Workshops and Seminars: Hands-on learning experiences",
      "Global Collaboration: Network with environmental engineers",
      "Real-World Applications: Solve engineering challenges effectively",
      "Ethical AI Integration: Promote responsible engineering practices",
      "Custom Learning Paths: Tailored to individual goals",
      "Cutting-Edge Curriculum: Stay ahead in environmental engineering",
      "Certification Programs: Advance your career in sustainable engineering",
    ],
  },
  "AI Board of Urban Sustainability": {
    heading: "AI Board of Urban Sustainability",
    mainHeading: "Building Sustainable Cities with Artificial Intelligence",
    summary: `
        The AI Board of Urban Sustainability integrates artificial intelligence with urban planning 
        to develop smarter cities, optimize resource usage, and promote sustainable urban growth. 
        It equips urban planners and professionals with tools to create eco-friendly, efficient, and 
        resilient city systems.
      `,
    highlights: [
      "Integrates AI with urban planning and sustainability",
      "Focuses on resource optimization and city resilience",
      "Provides training in AI-powered urban tools",
      "Hosts workshops on smart city design and AI",
      "Promotes sustainable urban growth through technology",
      "Explores real-world applications of AI in urban systems",
      "Supports interdisciplinary research in urban sustainability",
      "Develops strategies for eco-friendly city designs",
      "Encourages global collaboration among urban planners",
      "Fosters innovation in sustainable urban solutions",
    ],
    benefits: [
      "Learn to use AI tools for urban planning",
      "Design smarter cities with sustainable solutions",
      "Connect with global experts in urban sustainability",
      "Enhance career prospects in urban planning and AI",
      "Promote eco-friendly urban growth through innovative designs",
      "Participate in workshops on urban systems and AI",
      "Explore challenges in resource optimization and city resilience",
      "Stay updated with trends in urban sustainability research",
      "Address urban challenges effectively with AI",
      "Earn certifications in AI-integrated urban sustainability",
    ],
    features: [
      "AI-Powered Urban Tools: Build smarter cities",
      "Sustainable City Design Strategies: Promote efficient systems",
      "Expert-Led Training: Learn from urban planning specialists",
      "Workshops and Seminars: Practical learning opportunities",
      "Global Collaboration: Network with urban sustainability experts",
      "Real-World Applications: Solve urban challenges effectively",
      "Ethical AI Integration: Promote fairness in city planning",
      "Custom Learning Paths: Tailored to individual interests",
      "Cutting-Edge Curriculum: Stay ahead in urban planning innovations",
      "Certification Programs: Advance your career in sustainable cities",
    ],
  },
  "AI Board of Ecological Studies": {
    heading: "AI Board of Ecological Studies",
    mainHeading: "Understanding Ecosystems with Artificial Intelligence",
    summary: `
        The AI Board of Ecological Studies combines artificial intelligence with ecology to analyze 
        ecosystems, monitor biodiversity, and address environmental challenges. By integrating AI tools 
        with ecological research, the board empowers students and scientists to create impactful solutions 
        for environmental conservation.
      `,
    highlights: [
      "Integrates AI with ecological research",
      "Focuses on biodiversity monitoring and ecosystem analysis",
      "Provides training in AI-powered ecological tools",
      "Hosts workshops on ecosystem studies and AI",
      "Promotes ethical AI use in environmental conservation",
      "Explores real-world applications of AI in ecology",
      "Supports interdisciplinary research in ecological studies",
      "Develops strategies for protecting biodiversity",
      "Encourages global collaboration among ecologists",
      "Fosters innovation in ecosystem conservation solutions",
    ],
    benefits: [
      "Learn to monitor ecosystems with AI tools",
      "Protect biodiversity effectively using advanced technology",
      "Connect with global experts in ecological studies",
      "Enhance career prospects in ecology and AI",
      "Promote ethical practices in environmental conservation",
      "Participate in workshops on ecosystem studies and AI",
      "Explore challenges in biodiversity conservation",
      "Stay updated with trends in ecological research",
      "Address environmental challenges in ecosystems",
      "Earn certifications in AI-integrated ecological studies",
    ],
    features: [
      "AI-Powered Ecological Tools: Study ecosystems efficiently",
      "Biodiversity Conservation Strategies: Protect natural habitats",
      "Expert-Led Training: Learn from ecology specialists",
      "Workshops and Seminars: Hands-on learning opportunities",
      "Global Collaboration: Build networks with ecological researchers",
      "Real-World Applications: Solve biodiversity challenges",
      "Ethical AI Integration: Promote fairness in ecological research",
      "Custom Learning Paths: Tailored to individual goals",
      "Cutting-Edge Curriculum: Stay ahead in ecological science",
      "Certification Programs: Advance your career in ecological studies",
    ],
  },
  "AI Board of Teaching and Curriculum Design": {
    heading: "AI Board of Teaching and Curriculum Design",
    mainHeading: "Innovating Education with Artificial Intelligence",
    summary: `
        The AI Board of Teaching and Curriculum Design uses artificial intelligence to revolutionize 
        education by creating adaptive curriculums, enhancing teaching methods, and personalizing 
        learning experiences. It empowers educators and curriculum designers with tools to develop 
        impactful and modern educational systems.
      `,
    highlights: [
      "Integrates AI with teaching and curriculum design",
      "Focuses on personalized learning and adaptive curriculums",
      "Provides training in AI-powered educational tools",
      "Hosts workshops on innovative teaching strategies and AI",
      "Promotes technology-driven education solutions",
      "Explores real-world applications of AI in classrooms",
      "Supports interdisciplinary research in education and AI",
      "Develops strategies for modernizing curriculums",
      "Encourages global collaboration among educators",
      "Fosters innovation in teaching and learning methods",
    ],
    benefits: [
      "Learn to design adaptive curriculums with AI tools",
      "Enhance teaching methods with technology",
      "Connect with global experts in education and AI",
      "Promote personalized learning experiences for students",
      "Participate in workshops on teaching innovations",
      "Explore challenges in modernizing education systems",
      "Stay updated with trends in education technology",
      "Develop strategies for impactful curriculums",
      "Address diverse learning needs effectively",
      "Earn certifications in AI-integrated education design",
    ],
    features: [
      "AI-Powered Educational Tools: Enhance learning experiences",
      "Personalized Learning Strategies: Cater to individual needs",
      "Expert-Led Training: Learn from education specialists",
      "Workshops and Seminars: Practical learning opportunities",
      "Global Collaboration: Build networks with educators worldwide",
      "Real-World Applications: Solve challenges in education systems",
      "Ethical AI Integration: Promote fairness in learning",
      "Custom Learning Paths: Tailored to individual goals",
      "Cutting-Edge Curriculum: Stay ahead in teaching innovations",
      "Certification Programs: Advance your career in education design",
    ],
  },
  "AI Board of Linguistics": {
    heading: "AI Board of Linguistics",
    mainHeading: "Understanding Language with Artificial Intelligence",
    summary: `
        The AI Board of Linguistics combines artificial intelligence with the study of language 
        to explore how we communicate, analyze linguistic patterns, and develop innovative tools 
        like language translation and speech recognition. By integrating AI and linguistics, the 
        board empowers students and researchers to deepen their understanding of human language 
        and its applications in the modern world.
      `,
    highlights: [
      "Integrates AI with the study of linguistics",
      "Focuses on analyzing language patterns and structures",
      "Provides training in AI-powered linguistic tools",
      "Hosts workshops on computational linguistics and AI",
      "Promotes advancements in natural language processing",
      "Explores real-world applications of AI in language technology",
      "Supports interdisciplinary research in linguistics and AI",
      "Develops strategies for enhancing language learning",
      "Encourages global collaboration among linguists",
      "Fosters innovation in language-related technologies",
    ],
    benefits: [
      "Learn to analyze linguistic data with AI tools",
      "Enhance research in language patterns and structures",
      "Connect with global experts in linguistics and AI",
      "Promote advancements in natural language processing",
      "Participate in workshops on computational linguistics",
      "Explore challenges in modern language technologies",
      "Stay updated with trends in linguistics research",
      "Develop strategies for improving language learning",
      "Address linguistic challenges effectively with AI",
      "Earn certifications in AI-integrated linguistics studies",
    ],
    features: [
      "AI-Powered Linguistic Analysis: Study language structures efficiently",
      "Language Technology Tools: Advance in computational linguistics",
      "Expert-Led Training: Learn from linguistics and AI specialists",
      "Workshops and Seminars: Hands-on learning opportunities",
      "Global Collaboration: Build networks with linguistics researchers",
      "Real-World Applications: Solve challenges in language technologies",
      "Ethical AI Integration: Promote fairness in language technologies",
      "Custom Learning Paths: Tailored to individual interests",
      "Cutting-Edge Curriculum: Stay ahead in linguistic studies",
      "Certification Programs: Advance your career in linguistics",
    ],
  },
  "AI Board of English Language and Literature": {
    heading: "AI Board of English Language and Literature",
    mainHeading: "Exploring English Language and Literature with AI",
    summary: `
        The AI Board of English Language and Literature uses artificial intelligence to analyze 
        literary works, study language usage, and enhance teaching and learning methods. By combining 
        AI tools with English studies, the board empowers students and educators to explore the depth 
        of English literature and its modern applications.
      `,
    highlights: [
      "Integrates AI with English language and literature studies",
      "Focuses on analyzing literary works and language usage",
      "Provides training in AI-powered tools for English studies",
      "Hosts workshops on modern English teaching methods and AI",
      "Promotes advancements in computational literature analysis",
      "Explores real-world applications of AI in English education",
      "Supports interdisciplinary research in literature and AI",
      "Develops strategies for enhancing literary analysis",
      "Encourages global collaboration among English scholars",
      "Fosters innovation in teaching and studying literature",
    ],
    benefits: [
      "Learn to analyze literary works with AI tools",
      "Enhance understanding of English language patterns",
      "Connect with global experts in English studies and AI",
      "Promote advancements in literature analysis technologies",
      "Participate in workshops on English teaching and AI",
      "Explore challenges in modern English education",
      "Stay updated with trends in English language research",
      "Develop strategies for effective literary analysis",
      "Address educational challenges in English studies",
      "Earn certifications in AI-integrated English studies",
    ],
    features: [
      "AI-Powered Literary Tools: Study texts with precision",
      "Language Usage Analysis: Explore patterns and context",
      "Expert-Led Training: Learn from English literature specialists",
      "Workshops and Seminars: Enhance teaching and learning methods",
      "Global Collaboration: Build networks with English scholars",
      "Real-World Applications: Solve challenges in English education",
      "Ethical AI Integration: Promote fairness in literary studies",
      "Custom Learning Paths: Tailored to your interests",
      "Cutting-Edge Curriculum: Stay ahead in English studies",
      "Certification Programs: Boost your career in English literature",
    ],
  },
  "AI Board of Translation and Interpretation": {
    heading: "AI Board of Translation and Interpretation",
    mainHeading: "Transforming Translation and Interpretation with AI",
    summary: `
        The AI Board of Translation and Interpretation leverages artificial intelligence to improve 
        language translation, interpretation accuracy, and cross-cultural communication. By integrating 
        AI tools with translation studies, the board empowers professionals and researchers to bridge 
        language barriers efficiently and effectively.
      `,
    highlights: [
      "Integrates AI with translation and interpretation practices",
      "Focuses on improving language translation accuracy",
      "Provides training in AI-powered translation tools",
      "Hosts workshops on modern interpretation techniques and AI",
      "Promotes advancements in multilingual communication",
      "Explores real-world applications of AI in translation technology",
      "Supports interdisciplinary research in translation and AI",
      "Develops strategies for enhancing cross-cultural understanding",
      "Encourages global collaboration among translators",
      "Fosters innovation in translation and interpretation technologies",
    ],
    benefits: [
      "Learn to use AI tools for accurate translations",
      "Improve interpretation skills with advanced technology",
      "Connect with global experts in translation studies and AI",
      "Promote multilingual communication through AI innovations",
      "Participate in workshops on translation technologies",
      "Explore challenges in cross-cultural communication",
      "Stay updated with trends in translation and AI research",
      "Develop strategies for effective multilingual solutions",
      "Address global language barriers with AI tools",
      "Earn certifications in AI-integrated translation studies",
    ],
    features: [
      "AI-Powered Translation Tools: Bridge language gaps efficiently",
      "Interpretation Accuracy Enhancements: Use cutting-edge technology",
      "Expert-Led Training: Learn from translation and AI specialists",
      "Workshops and Seminars: Improve practical interpretation skills",
      "Global Collaboration: Build networks with language professionals",
      "Real-World Applications: Solve translation challenges with AI",
      "Ethical AI Integration: Promote fairness in multilingual solutions",
      "Custom Learning Paths: Tailored to individual interests",
      "Cutting-Edge Curriculum: Stay ahead in translation studies",
      "Certification Programs: Advance your career in translation and AI",
    ],
  },
  "AI Board of Comparative Literature": {
    heading: "AI Board of Comparative Literature",
    mainHeading: "Reimagining Comparative Literature with AI",
    summary: `
        The AI Board of Comparative Literature uses artificial intelligence to study literature across 
        cultures, analyze themes, and explore intertextual connections. By integrating AI tools and 
        comparative literature practices, the board empowers students and scholars to uncover new 
        perspectives in literary studies.
      `,
    highlights: [
      "Integrates AI with comparative literature studies",
      "Focuses on analyzing literary themes across cultures",
      "Provides training in AI-powered literary analysis tools",
      "Hosts workshops on intertextuality and AI",
      "Promotes advancements in cross-cultural literary studies",
      "Explores real-world applications of AI in literature comparison",
      "Supports interdisciplinary research in literature and AI",
      "Develops strategies for enhancing global literary understanding",
      "Encourages collaboration among comparative literature scholars",
      "Fosters innovation in analyzing diverse literary traditions",
    ],
    benefits: [
      "Learn to analyze cross-cultural literature with AI tools",
      "Discover intertextual connections across global texts",
      "Connect with global experts in comparative literature and AI",
      "Promote advancements in global literary studies",
      "Participate in workshops on literature comparison and AI",
      "Explore challenges in cross-cultural literary analysis",
      "Stay updated with trends in comparative literature research",
      "Develop strategies for effective literary studies",
      "Address intertextuality challenges with AI tools",
      "Earn certifications in AI-integrated comparative literature",
    ],
    features: [
      "AI-Powered Literary Comparison: Analyze diverse texts efficiently",
      "Intertextuality Analysis Tools: Discover thematic connections",
      "Expert-Led Training: Learn from literature specialists",
      "Workshops and Seminars: Deepen understanding of global texts",
      "Global Collaboration: Build networks with literary scholars",
      "Real-World Applications: Solve challenges in comparative studies",
      "Ethical AI Integration: Promote fairness in global literature",
      "Custom Learning Paths: Tailored to your interests",
      "Cutting-Edge Curriculum: Stay ahead in comparative studies",
      "Certification Programs: Advance your career in literary research",
    ],
  },
  "AI Board of Multilingual Studies": {
    heading: "AI Board of Multilingual Studies",
    mainHeading: "Exploring Multilingualism with Artificial Intelligence",
    summary: `
        The AI Board of Multilingual Studies leverages artificial intelligence to study and promote 
        multilingual communication, language learning, and cross-linguistic understanding. By integrating 
        AI tools with multilingual practices, the board empowers students and educators to foster 
        global connections and cultural exchange.
      `,
    highlights: [
      "Integrates AI with multilingual communication studies",
      "Focuses on promoting cross-linguistic understanding",
      "Provides training in AI-powered multilingual tools",
      "Hosts workshops on language learning and AI",
      "Promotes advancements in multilingual education",
      "Explores real-world applications of AI in multilingualism",
      "Supports interdisciplinary research in language studies and AI",
      "Develops strategies for effective multilingual communication",
      "Encourages global collaboration among language professionals",
      "Fosters innovation in cross-linguistic solutions",
    ],
    benefits: [
      "Learn to analyze multilingual communication with AI tools",
      "Promote global connections through multilingualism",
      "Connect with global experts in multilingual studies and AI",
      "Enhance career prospects in language education and AI",
      "Promote cultural exchange through innovative language tools",
      "Participate in workshops on multilingual technologies",
      "Explore challenges in cross-linguistic understanding",
      "Stay updated with trends in multilingualism research",
      "Address global communication barriers with AI tools",
      "Earn certifications in AI-integrated multilingual studies",
    ],
    features: [
      "AI-Powered Multilingual Tools: Enhance global communication",
      "Language Learning Strategies: Promote cross-cultural exchange",
      "Expert-Led Training: Learn from multilingual education specialists",
      "Workshops and Seminars: Develop practical language skills",
      "Global Collaboration: Build networks with language professionals",
      "Real-World Applications: Solve challenges in multilingual education",
      "Ethical AI Integration: Promote fairness in multilingualism",
      "Custom Learning Paths: Tailored to individual interests",
      "Cutting-Edge Curriculum: Stay ahead in multilingual studies",
      "Certification Programs: Advance your career in global communication",
    ],
  },
};

export default boardsData;
